import React from 'react'
import PropTypes from 'prop-types'
import { matchRoutes, useLocation, useNavigate } from 'react-router'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { RESET_PASSWORD_ROUTES, ROUTES } from 'consts'
import { PROFILE_ROUTES } from 'containers/Profile/consts'
import { ArrowLongLeft } from 'components/Icons'
import { BackBtn, BtnText, LoggedOutHeader, LoggedOutWrapper } from './styles'

import Logo from './Logo'
import messages from './messages'

const BACK_BUTTON_OPTIONS = {
  [PROFILE_ROUTES.ACTIVATE_2FA]: {
    title: messages.cancelActivation,
    route: ROUTES.PROFILE,
  },
  [PROFILE_ROUTES.DEACTIVATE_2FA]: {
    title: messages.cancelDeactivation,
    route: ROUTES.PROFILE,
  },
  default: {
    title: messages.login,
    route: ROUTES.LOGIN,
  },
}

const SimpleHeader = ({ isMobile, isLoggedIn }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  // TODO: remove after switch to new header for the rest of the screens (register, set pass, etc)
  const location = useLocation()
  const isButtonDisplayed = matchRoutes(
    [
      { path: RESET_PASSWORD_ROUTES.INIT },
      { path: RESET_PASSWORD_ROUTES.CONFIRM },
      { path: ROUTES.SIGN_UP },
      { path: ROUTES.EMAIL_CONFIRMATION },
      { path: PROFILE_ROUTES.ACTIVATE_2FA },
      { path: PROFILE_ROUTES.DEACTIVATE_2FA },
      { path: ROUTES.KNOWLEDGE_BASE },
    ],
    location,
  )

  const { title: btnTitle, route } =
    BACK_BUTTON_OPTIONS[location.pathname] || BACK_BUTTON_OPTIONS.default

  return (
    <LoggedOutWrapper {...{ isMobile }} data-test-id="simple-header">
      <LoggedOutHeader>
        {isButtonDisplayed && (
          <BackBtn data-test-id="back_button" onClick={() => navigate(route)}>
            <ArrowLongLeft color={theme.colors.primary} />
            <BtnText>{formatMessage(btnTitle)}</BtnText>
          </BackBtn>
        )}
        <div className="absolute left-1/2 -translate-x-1/2">
          <Logo isLoggedIn={isLoggedIn} />
        </div>
      </LoggedOutHeader>
    </LoggedOutWrapper>
  )
}

SimpleHeader.propTypes = {
  isLoggedIn: PropTypes.bool,
}

export default SimpleHeader
