import React, { useCallback, useMemo, useState } from 'react'
import { debounce, identity } from 'lodash'

import { SearchV2 as SearchIcon, X as ClearIcon } from 'components/Icons'
import { cn } from 'utils'

const TwSearch = ({
  className,
  placeholder,
  ref,
  onSearch = identity,
  validateSearch,
  value: passedValue,
  setValue: passedSetValue,
}) => {
  const [uncontrolledValue, setUncontrolledValue] = useState('')

  const value = passedValue ?? uncontrolledValue
  const setValue = passedSetValue ?? setUncontrolledValue

  const handleSearch = useCallback(
    newValue => {
      if (!newValue) {
        onSearch('')
      }

      if (validateSearch && !validateSearch(newValue)) {
        return
      }

      onSearch(newValue)
    },
    [validateSearch, onSearch],
  )

  const debouncedSearch = useMemo(() => debounce(handleSearch, 500), [
    handleSearch,
  ])

  const handleInputChange = useCallback(
    e => {
      setValue(e.target.value)
      debouncedSearch(e.target.value)
    },
    [setValue, debouncedSearch],
  )

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        debouncedSearch.cancel()
        handleSearch(e.target.value)
      }
    },
    [debouncedSearch, handleSearch],
  )

  const handleSearchClear = useCallback(
    () => {
      debouncedSearch.cancel()
      setValue('')
      handleSearch('')
    },
    [debouncedSearch, handleSearch],
  )

  return (
    <div className={cn('relative', className)}>
      <div className="absolute top-2 left-4">
        <SearchIcon />
      </div>
      <input
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        ref={ref}
        placeholder={placeholder}
        className="hover:bg-grey-350 focus:bg-grey-350 bg-grey-150 h-10 rounded-lg w-full py-2 pr-12 pl-14 text-label-13 placeholder-grey-600 outline-hidden"
      />
      {value && (
        <button
          type="button"
          className="group flex justify-center absolute top-2 right-4 w-8"
          onClick={handleSearchClear}
        >
          <ClearIcon className="[&_path]:stroke-grey-500 [&_path]:group-hover:stroke-blue-900" />
        </button>
      )}
    </div>
  )
}

export default TwSearch
