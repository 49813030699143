import React from 'react'

import { cn } from 'utils'

const Item = ({
  children,
  title,
  icon,
  onClick,
  isHighlighted,
  isDisabled,
  testId,
}) => {
  const dataTestId = testId ?? title
  return (
    <div
      data-test-id={`kb-list-item#${dataTestId}`}
      className={cn(
        'flex items-center rounded-lg',
        isHighlighted && 'bg-grey-200',
      )}
    >
      <button
        type="button"
        data-test-id={`kb-list-item-btn#${dataTestId}`}
        disabled={isHighlighted}
        className={cn(
          'flex w-full px-4 py-2 gap-4 hover:bg-grey-200',
          children ? 'rounded-s-lg' : 'rounded-lg',
          isDisabled && 'bg-transparent',
          isHighlighted && 'hover:bg-transparent',
        )}
        onClick={onClick}
      >
        <img alt="" src={icon} />
        <span
          className={cn(
            'text-blue-900 text-[12px] leading-6 font-medium text-left flex flex-1 gap-4 items-center',
            isDisabled && 'text-grey-500',
          )}
        >
          {title}
        </span>
      </button>
      {children}
    </div>
  )
}

export default Item
