import React, { useMemo } from 'react'
import { useParams } from 'react-router'

import { cn } from 'utils'
import RecommendedBox from 'components/RecommendedBox'
import { RECOMMENDATION_PAGE_NAMES } from 'components/RecommendedBox/consts'
import { ITEM_LIST_IDS, ITEM_LIST_NAMES } from 'services/analytics'

const Recommendations = ({ isEmptyListing, cuisineFeatures }) => {
  const {
    category: categoryId,
    group: groupId,
    subgroup: subgroupId,
  } = useParams()

  const { itemListId, itemListName } = useMemo(
    () => {
      if (categoryId) {
        const param = `${ITEM_LIST_IDS.RECOMMENDATION_CATEGORY}${categoryId}`
        return {
          itemListId: param,
          itemListName: param,
        }
      }

      return {
        itemListId: ITEM_LIST_IDS.RECOMMENDATION_ALL_PRODUCTS,
        itemListName: ITEM_LIST_NAMES.RECOMMENDATION_ALL_PRODUCTS,
      }
    },
    [categoryId],
  )

  return (
    <RecommendedBox
      categoryId={categoryId}
      groupId={groupId}
      subgroupId={subgroupId}
      cuisineFeatures={cuisineFeatures}
      pageName={RECOMMENDATION_PAGE_NAMES.CATEGORY_PAGE}
      itemListId={itemListId}
      itemListName={itemListName}
      className={cn(
        'py-10 bg-grey-100 gap-10 xl:rounded-3xl',
        isEmptyListing && 'bg-white mt-24',
      )}
      fadeClassName={cn('from-grey-100 w-4', isEmptyListing && 'from-white')}
    />
  )
}

export default Recommendations
