import React from 'react'
import { useIntl } from 'react-intl'

import { Filtering as FilteringIcon, XNormal as XIcon } from 'components/Icons'
import NoResultsV2 from 'components/NoResultsV2'

import messages from './messages'

const FilteringNoResults = ({ onClearFilters }) => {
  const { formatMessage } = useIntl()

  return (
    <NoResultsV2
      icon={<FilteringIcon />}
      text={formatMessage(messages.lackOfProducts)}
      btnIcon={<XIcon />}
      btnText={formatMessage(messages.clearFiltersBtn)}
      handleBtnClick={onClearFilters}
    />
  )
}

export default FilteringNoResults
