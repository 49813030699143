import React, { useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Flex } from '@rebass/grid'
import { SwitchTransition, Transition } from 'react-transition-group'
import { get } from 'lodash/fp'

import theme from 'theme'
import { useOfMaxWidth } from 'hooks'

import {
  useGetStorageValue,
  CONFIG_SCHEMA_KEYS,
  CONFIG_API_PATHS_MAP,
} from 'containers/Storage'
import { useUpdateStorageMutation } from 'services/api/rtkApi'

import { HintModal, HintOverlay } from 'components'
import { TertiaryText } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'
import { ITEM_LIST_IDS, ITEM_LIST_NAMES } from 'services/analytics'

import ProductImage, { PRODUCT_IMAGE_SIZES } from '../../ProductImage'
import TemplateStar, { StarContainer } from '../TemplateStar'
import { VerticalDivider } from '../styledComponents'
import { duration, defaultStyle, transitionStyles } from '../fadeInConsts'
import messages from '../messages'

import { hintModalConfig } from './hintModalConfig'
import { ImgContainer, Calendar, MobileIconsWrapper, XIcon } from './styles'

const ImageAndCtas = ({
  onStarClick,
  productPath,
  outOfStock,
  changingDate,
  toggleDateChanging,
  showDateHint,
  hasMultipleVariants,
  isInactive,
  product: { id: productId, unitsOfMeasure },
  product,
  unitOfMeasureObj,
  onTrackProductClick,
}) => {
  const { formatMessage } = useIntl()
  const [updateStorage] = useUpdateStorageMutation()

  const ref = useRef(null)
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const [dateHintIndex, setDateHintIndex] = useState(-1)
  const dateHintEnabled =
    useGetStorageValue(CONFIG_SCHEMA_KEYS.CART_GUIDE.CHANGE_DATE_SINGLE) &&
    showDateHint

  const showIconsBelow = isMobile && !isInactive
  const calendarRendered = (
    <HintOverlay
      mr={theme.spacing.sm}
      enabled={dateHintEnabled}
      withTooltip
      tooltipText={formatMessage(messages.moveSingleProduct)}
      clickHandler={() => {
        setDateHintIndex(0)
        toggleDateChanging()
      }}
    >
      <Calendar
        data-test-id={`product_calendar_btn_${productId}`}
        onClick={() => {
          if (dateHintEnabled) setDateHintIndex(0)
          toggleDateChanging()
        }}
      />
    </HintOverlay>
  )
  const starRendered = hasMultipleVariants ? (
    <StarContainer withoutMargin={isMobile} />
  ) : (
    <TemplateStar
      {...{
        productId,
        product,
        unitsOfMeasure,
        onStarClick,
      }}
      unitOfMeasure={get('unitOfMeasure')(unitOfMeasureObj)}
      withoutMargin={isMobile}
    />
  )

  return (
    <Flex height={isMobile ? 'auto' : '48px'}>
      <SwitchTransition>
        <Transition
          key={!isMobile && changingDate}
          nodeRef={ref}
          timeout={duration}
        >
          {state => (
            <Flex
              alignItems={isMobile ? 'flex-start' : 'center'}
              style={{ ...defaultStyle, ...transitionStyles[state] }}
              ref={ref}
            >
              {!isMobile && changingDate ? (
                <>
                  <XIcon onClick={() => toggleDateChanging()} />

                  <TertiaryText>{formatMessage(messages.move)}</TertiaryText>
                </>
              ) : (
                <>
                  {!isMobile &&
                    (isInactive ? (
                      <div style={{ width: '40px' }} />
                    ) : (
                      calendarRendered
                    ))}

                  <Flex flexDirection="column" height="100%">
                    <ImgContainer
                      to={productPath}
                      state={{
                        itemListId: ITEM_LIST_IDS.CART,
                        itemListName: ITEM_LIST_NAMES.CART,
                      }}
                      onClick={onTrackProductClick}
                    >
                      <ProductImage
                        {...{ product, outOfStock }}
                        size={PRODUCT_IMAGE_SIZES.S}
                      />
                    </ImgContainer>

                    {showIconsBelow && (
                      <MobileIconsWrapper>
                        {calendarRendered}

                        {starRendered}
                      </MobileIconsWrapper>
                    )}
                  </Flex>
                </>
              )}

              <VerticalDivider
                mx={isMobile ? theme.spacing.xs : theme.spacing.sm}
              />

              {!isMobile && !changingDate && !isInactive && starRendered}
            </Flex>
          )}
        </Transition>
      </SwitchTransition>

      {dateHintIndex > -1 && (
        <HintModal
          {...hintModalConfig[dateHintIndex]}
          onClose={() => {
            setDateHintIndex(-1)
            updateStorage({
              path: CONFIG_API_PATHS_MAP.CART_GUIDE.CHANGE_DATE_SINGLE,
              value: false,
            })
          }}
        />
      )}
    </Flex>
  )
}

export default ImageAndCtas
