import React from 'react'

import TwButton, { BUTTON_VARIANTS } from 'components/Button/TwButton'

const NoResults = ({ icon, text, btnIcon, btnText, handleBtnClick }) => (
  <div className="flex flex-col gap-8 items-center">
    <div className="flex flex-col items-center gap-4">
      {icon}
      <span className="text-13 text-center text-balance">{text}</span>
    </div>
    <TwButton
      onClick={handleBtnClick}
      variant={BUTTON_VARIANTS.secondary}
      className=""
    >
      <div className="flex gap-4 items-center">
        {btnIcon}
        <span className="text-13md">{btnText}</span>
      </div>
    </TwButton>
  </div>
)

export default NoResults
