import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { Link, InlineText, HeaderLink, PageHeader } from 'components/Text'
import { ProductsGrid } from 'views/Products/styledComponents/Layout'
import { APP_BREAKPOINTS } from 'consts'
import { PreselectedFilterPagesContainer } from 'views/Dashboard/FilteredPagesTile/styles'
import { CampaignsTileContainer } from 'views/Dashboard/Campaigns/styles'

export const DashboardTilesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${theme.spacing.sm};
  grid-row-gap: ${theme.spacing.sm};
  margin: ${theme.spacing.base} 0;

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    grid-template-columns: repeat(2, 1fr);

    ${CampaignsTileContainer} {
      grid-column: 1 / -1;
      grid-row: 2;
      max-height: 440px;
    }
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    grid-template-columns: repeat(3, 1fr);

    ${CampaignsTileContainer} {
      grid-column-start: ${p => (p.$fullCampaigns ? 1 : 2)};
      grid-column-end: 4;
    }

    ${p =>
      p.$isTopCampaignsLayout &&
      css`
        ${PreselectedFilterPagesContainer} {
          grid-row: 1;
          grid-column: 3;
        }
        ${CampaignsTileContainer} {
          grid-column: ${p.$fullCampaigns ? '1 / -1' : '1 / 3'};
          grid-row: 1;
        }
      `};
  }
`

export const SingleBox = styled.div`
  display: flex;
  padding-left: ${theme.spacing.base};
  padding-right: ${theme.spacing.base};
  padding-bottom: ${theme.spacing.base};
  box-sizing: border-box;
  flex-direction: column;
  min-width: 296px;
  flex: 1;
  height: 364px;
  margin-bottom: ${theme.spacing.base};
  border: 1px solid rgba(221, 226, 229, 0.5);
`

export const Panel = styled.div`
  display: flex;
  align-items: center;
  height: 96px;
  min-height: 96px;
  max-height: 96px;
  border-bottom: 1px solid rgba(221, 226, 229, 0.5);
`
export const Circle = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-image: url("${({ imageUrl }) => imageUrl}");
  background-position: center center;
  background-size: cover;
  margin-left: auto;
`
export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  padding-top: ${theme.spacing.base};
  flex-direction: column;
  justify-content: space-between;
`
export const CategoryLink = styled(HeaderLink)`
  padding-right: 20px;
  max-width: 210px;
`

export const SubCategoryLink = styled(Link)`
  color: ${theme.colors.primary};
  line-height: ${theme.lineHeights.md};
  margin-bottom: ${theme.spacing.xs};
  font-size: ${theme.textSizes.l};
  :hover {
    color: ${theme.colors.secondary};
  }
`
export const MoreLink = styled(Link)`
  color: ${theme.colors.secondary};
  font-size: ${theme.headingSize.sm};
  font-weight: ${theme.fontWeights.strongBold};
  text-transform: uppercase;
  line-height: 15px;
  letter-spacing: 0.08em;
  :hover {
    color: ${theme.colors.secondary};
  }
`

export const GroupsContainer = styled(Flex)`
  flex-direction: column;
  max-height: 198px;
  overflow: auto;
`

export const Description = styled(Flex)`
  flex-direction: column;
  max-height: 198px;
  line-height: 24px;
`

export const NumberText = styled(InlineText)`
  color: ${theme.colors.gray4};
  font-size: ${theme.textSizes.sm};
  margin-top: 4px;
`

export const SubcategoryNumberText = styled(NumberText)`
  margin-left: ${theme.spacing.xs};
`

export const CategoriesSection = styled(ProductsGrid)`
  padding-top: ${theme.spacing.base};
  grid-row-gap: 0;
`

export const DashboardHeaderContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    margin-top: 40px;
  }
`

export const HeaderActionsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  align-items: center;
`

export const HeaderActionsSeparator = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${theme.colors.semiTranspBg.gray};
`

export const DashboardHeader = styled(PageHeader)`
  margin-top: 48px;
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin-top: 0;
  }
`
