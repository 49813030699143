import React from 'react'

import theme from 'theme'

const Info = ({
  primaryColor = theme.colors.primary,
  secondaryColor = theme.colors.gray5,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66667 7C4.22464 7 3.80072 7.18262 3.48816 7.50768C3.17559 7.83274 3 8.27362 3 8.73333V13.9333C3 14.393 3.17559 14.8339 3.48816 15.159C3.80072 15.484 4.22464 15.6667 4.66667 15.6667H5.5L6.19095 19.2596C6.2736 19.6894 6.64964 20 7.08727 20C7.59135 20 8 19.5914 8 19.0873V9C8 7.89543 7.10457 7 6 7H4.66667Z"
      fill={secondaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2857 8.33336V3.80955C19.2857 3.56959 19.1904 3.33946 19.0207 3.16978C18.851 3.00011 18.6209 2.90479 18.381 2.90479H17.8904C17.6252 2.90479 17.3734 3.01264 17.1737 3.18721C15.7599 4.42331 13.6012 5.25328 11.7821 5.77652C10.8855 6.03444 10.2381 6.8354 10.2381 7.7684V14.3269C10.2381 15.2599 10.8855 16.0609 11.7821 16.3188C13.6012 16.842 15.7599 17.672 17.1737 18.9081C17.3734 19.0826 17.6252 19.1905 17.8904 19.1905H18.381C18.6209 19.1905 18.851 19.0952 19.0207 18.9255C19.1904 18.7558 19.2857 18.5257 19.2857 18.2857V13.7619V12.8002V9.29512V8.33336Z"
      fill={primaryColor}
    />
    <path
      d="M20 13C21.1046 13 22 12.1046 22 11C22 9.89543 21.1046 9 20 9V13Z"
      fill={secondaryColor}
    />
  </svg>
)

export default Info
