import React from 'react'
import { useSelector } from 'react-redux'
import useEmblaCarousel from 'embla-carousel-react'

import { useQueryFilters } from 'hooks'
import { trackCatalogCategory } from 'services/analytics'
import { useParsedChildGroups } from 'views/Products/utils'
import { Gradient } from 'views/Products/styledComponents'
import GroupButtonsSkeleton from 'views/Products/Header/GroupButtons/GroupButtonsSkeleton'
import { isFetchingSelector } from 'hocs/selectors'
import {
  ALL_CATEGORIES_LIST_REDUCER_NAME,
  CATEGORY_LIST_REDUCER_NAME,
  CATEGORY_REDUCER_NAME,
  SELECTED_CATEGORY_GROUP_REDUCER_NAME,
  SELECTED_CATEGORY_REDUCER_NAME,
  SELECTED_CATEGORY_SUBGROUP_REDUCER_NAME,
} from 'containers/Categories'
import { stringifyQuery } from 'utils'
import { ProductCount } from 'views/Products/Header/styledComponents'

import {
  GroupButton,
  InnerWrapper,
} from 'views/Products/Header/GroupButtons/styles'

const GroupButtonsScroll = ({ group, fetching }) => {
  const { selectedFiltersParams, searchQuery } = useQueryFilters()

  const queryParams = stringifyQuery({
    filters: selectedFiltersParams,
    search: searchQuery,
  })

  const [emblaRef] = useEmblaCarousel({
    dragFree: true,
  })

  return (
    <div className="overflow-hidden px-6 pb-4" ref={emblaRef}>
      <div className="flex *:mr-2 *:flex-[0_0_auto] *:max-w-full">
        {fetching ? (
          <GroupButtonsSkeleton />
        ) : (
          group.map(({ id, name, path, productCount }) => (
            <GroupButton
              key={id}
              to={`${path}${queryParams}`}
              onClick={() => {
                trackCatalogCategory(name)
              }}
            >
              <InnerWrapper>
                {name}
                <ProductCount>{productCount}</ProductCount>
              </InnerWrapper>
            </GroupButton>
          ))
        )}
      </div>
    </div>
  )
}

const GroupButtonsComponent = () => {
  const parsedChildGroups = useParsedChildGroups()
  const { fetching } = useSelector(
    isFetchingSelector([
      [CATEGORY_REDUCER_NAME, ALL_CATEGORIES_LIST_REDUCER_NAME],
      [CATEGORY_REDUCER_NAME, CATEGORY_LIST_REDUCER_NAME],
      [CATEGORY_REDUCER_NAME, SELECTED_CATEGORY_REDUCER_NAME],
      [CATEGORY_REDUCER_NAME, SELECTED_CATEGORY_GROUP_REDUCER_NAME],
      [CATEGORY_REDUCER_NAME, SELECTED_CATEGORY_SUBGROUP_REDUCER_NAME],
    ]),
  )

  if (fetching || parsedChildGroups?.length) {
    return (
      <Gradient>
        <GroupButtonsScroll group={parsedChildGroups} fetching={fetching} />
      </Gradient>
    )
  }

  return null
}

export default GroupButtonsComponent
