import React from 'react'

import { cn } from 'utils'

// just put it into container containing carousel (inside parent of element to which emblas ref is assigned)
// in case carousel isn't full screen width, container has to be relative

const CarouselBorderFade = ({
  vertical,
  leftTopClassName,
  rightBottomClassName,
}) => (
  <>
    <div
      className={cn(
        'absolute top-0 left-0 h-full w-6 bg-linear-to-r from-white to-transparent z-1',
        vertical && 'w-full h-6 bg-linear-to-b',
        leftTopClassName,
      )}
    />
    <div
      className={cn(
        'absolute top-0 right-0 h-full w-6 bg-linear-to-l from-white to-transparent z-1',
        vertical && 'w-full h-6 top-auto bottom-0 bg-linear-to-t',
        rightBottomClassName,
      )}
    />
  </>
)

export default CarouselBorderFade
