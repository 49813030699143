import React from 'react'

import {
  ArrowLongLeft as ArrowLeft,
  ArrowLongRight as ArrowRight,
} from 'components/Icons'

import CarouselTracker from './CarouselTracker'

const DefaultCarouselControls = ({
  handlePrevClick,
  handleNextClick,
  snaps,
  selectedSnapIndex,
  nextBtnDisabled,
  prevBtnDisabled,
}) => (
  <div className="hidden lg:flex justify-center gap-2">
    <button
      type="button"
      className="p-2 w-10 h-10 bg-transparent rounded-lg hover:bg-grey-350 [&_path]:stroke-grey-500 disabled:hover:bg-transparent disabled:[&_path]:stroke-grey-350 transition"
      disabled={prevBtnDisabled}
      onClick={handlePrevClick}
      data-test-id="prev-page-btn"
    >
      <ArrowLeft />
    </button>
    <CarouselTracker snaps={snaps} selectedSnapIndex={selectedSnapIndex} />
    <button
      type="button"
      className="p-2 w-10 h-10 bg-transparent rounded-lg hover:bg-grey-350 [&_path]:stroke-grey-500 disabled:hover:bg-transparent disabled:[&_path]:stroke-grey-350 transition"
      disabled={nextBtnDisabled}
      onClick={handleNextClick}
      data-test-id="next-page-btn"
    >
      <ArrowRight />
    </button>
  </div>
)

export default DefaultCarouselControls
