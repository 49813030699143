import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import { PopoverClose } from 'components/Popup'
import { FEED_MESSAGE_MAP } from '../consts'
import EndOfListComponent from './EndOfListComponent'
import NotificationFeedItem from './NotificationFeedItem'
import NotificationFeedSection from './NotificationFeedSection'
import useFeedItemClickHandler from './utils/useFeedItemClickHandler'

import messages from './messages'
import { Container, FeedContainer, Header, ScrollableContainer } from './styles'

const SECTION_TYPE = {
  CURRENT: 'current',
  OLDER: 'older',
}

const NotificationCenterModal = ({ items, markAsRead, markAllAsRead }) => {
  const { formatMessage } = useIntl()

  const [handleFeedItemClick] = useFeedItemClickHandler(markAsRead)

  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)

  const currentWeek = dayjs().week() // Get the current week number
  const feedSections = useMemo(
    () => {
      const sections = items.reduce(
        (acc, item) => {
          const [currentWeekList, restList] = acc

          const itemWeek = dayjs(item.inserted_at).week()
          if (itemWeek === currentWeek) {
            currentWeekList.items.push(item)
          } else {
            restList.items.push(item)
          }

          return acc
        },
        [
          {
            title: formatMessage(messages.currentWeek),
            type: SECTION_TYPE.CURRENT,
            items: [],
          },
          {
            title: formatMessage(messages.older),
            type: SECTION_TYPE.OLDER,
            items: [],
          },
        ],
      )

      return sections.filter(section => section.items.length)
    },
    [currentWeek, items],
  )

  return (
    <Container id="notification-center-modal">
      {isTablet && <Header heading={formatMessage(messages.title)} />}
      <FeedContainer>
        <ScrollableContainer>
          {feedSections.map((section, index) => (
            <NotificationFeedSection
              title={section.title}
              key={section.type}
              displayReadAllBtn={index === 0}
              markAllAsRead={markAllAsRead}
            >
              {section.items.map(item => {
                const {
                  titleKey,
                  IconComponent,
                  stylingType,
                } = FEED_MESSAGE_MAP[item.source.key]

                const title = titleKey
                  ? formatMessage(titleKey)
                  : item.data.custom_title

                return (
                  <PopoverClose key={item.id}>
                    <NotificationFeedItem
                      innerHtml={item.blocks[0].rendered}
                      title={title}
                      IconComponent={IconComponent}
                      testId={`notification-${item.id}`}
                      onReadClick={e => {
                        e.stopPropagation()
                        markAsRead(item)
                      }}
                      isRead={!!item.read_at}
                      insertedAt={item.inserted_at}
                      onClick={() => handleFeedItemClick(item)}
                      stylingType={stylingType}
                    />
                  </PopoverClose>
                )
              })}
            </NotificationFeedSection>
          ))}
        </ScrollableContainer>
        <EndOfListComponent empty={!items.length} />
      </FeedContainer>
    </Container>
  )
}

export default NotificationCenterModal
