import { useEffect, useState } from 'react'
import { useContentful } from 'utils/contentful'
import { isArray } from 'lodash/fp'
import { useNavigate, useSearchParams } from 'react-router'

const parseArticlesData = articles => {
  let items = articles
  if (!isArray(items)) {
    items = articles.items
  }
  return items.map(({ fields, sys }) => ({
    title: fields.title,
    desc: fields.description,
    articleId: sys.id,
    body: fields.body,
  }))
}

const parseCategoriesData = categories =>
  categories.map(({ fields, sys }) => ({
    articles: parseArticlesData(fields.articles),
    title: fields.data.fields.title,
    icon: fields.data.fields.menuIcon.fields.file.url,
    categoryId: sys.id,
    img: fields.data.fields.backgroundImage.fields.file.url,
    theme: fields.data.fields.theme.fields,
    isPublic: fields.data.fields.public,
  }))

const parseFeaturedCategoriesData = featuredCategories =>
  featuredCategories.map(({ fields }) => ({
    category: parseCategoriesData([fields.category])[0],
    articles: parseArticlesData(fields.articles),
  }))

const parseFeaturedArticlesData = (categories, featuredArticles) => {
  if (!featuredArticles) return []

  return featuredArticles.map(({ fields: { title, article } }) => {
    const parsedArticle = parseArticlesData([article])[0]
    const linkedCategory = categories.find(({ articles }) =>
      articles.some(({ articleId }) => articleId === parsedArticle.articleId),
    )

    return {
      title,
      categoryId: linkedCategory?.categoryId,
      article: parsedArticle,
    }
  })
}

const parseFeaturedQuestionsData = featuredQuestions => {
  if (!featuredQuestions) return []

  return featuredQuestions.map(({ fields: { title, body } }) => ({
    title,
    body,
  }))
}

const parseKnowledgeBaseData = data => {
  const {
    categories,
    featuredCategories,
    featuredArticles,
    featuredQuestions,
  } = data.items[0].fields
  const parsedCategories = parseCategoriesData(categories)

  return {
    categories: parsedCategories,
    featuredCategories: parseFeaturedCategoriesData(featuredCategories),
    featuredArticles: parseFeaturedArticlesData(
      parsedCategories,
      featuredArticles,
    ),
    featuredQuestions: parseFeaturedQuestionsData(featuredQuestions),
  }
}

export const useKnowledgeBaseCategories = () => {
  const [filteredCategories, setFilteredCategories] = useState([])
  // to wait for filtering to update, not just fetch
  const [isListLoading, setIsListLoading] = useState(false)
  const [isListFiltered, setIsListFiltered] = useState(false)

  const {
    fetchEntries: fetchKnowledgeBase,
    isLoading: isKnowledgeBaseLoading,
    isInitialized,
    items,
  } = useContentful({
    clientQuery: {
      include: 3,
      content_type: 'knowledgeBase',
      'fields.platform': 'web',
    },
    responseParser: parseKnowledgeBaseData,
  })

  const {
    categories = [],
    featuredCategories = [],
    featuredArticles = [],
    featuredQuestions = [],
  } = items

  useEffect(() => {
    fetchKnowledgeBase()
  }, [])

  useEffect(
    () => {
      setFilteredCategories(items.categories)
    },
    [items],
  )

  const { fetchEntries: fetchArticles, items: articles } = useContentful({
    clientQuery: {
      content_type: 'knowledgeBaseArticle',
    },
    responseParser: parseArticlesData,
  })

  const filterArticles = query => {
    setIsListFiltered(!!query)

    if (categories.length) {
      setIsListLoading(true)
      fetchArticles({
        query,
        'sys.id[in]': categories.reduce((acc, item) => {
          let newRes = acc
          item.articles.forEach(article => {
            newRes = `${newRes}${article.articleId},`
          })
          return newRes
        }, ''),
      })
    }
  }

  useEffect(
    () => {
      const articleIds = articles.map(article => article.articleId)

      const newCategories = categories.reduce((acc, category) => {
        const newCategory = {
          ...category,
          articles: category.articles.filter(article =>
            articleIds.includes(article.articleId),
          ),
        }
        if (newCategory.articles.length) {
          return [...acc, newCategory]
        }

        return acc
      }, [])

      setFilteredCategories(newCategories)
      setIsListLoading(false)
    },
    [articles],
  )

  return {
    isKnowledgeBaseLoading,
    isListLoading: isListLoading || isKnowledgeBaseLoading,
    categories,
    featuredCategories,
    featuredArticles,
    featuredQuestions,
    filteredCategories,
    fetchFilteredArticles: filterArticles,
    isListFiltered,
    isInitialized,
  }
}

export const getPublicCategories = categories =>
  categories.filter(({ isPublic }) => isPublic)

export const useKnowledgeBaseMenuNavigate = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const search = searchParams.get('kb_search')
  const query = search ? `?kb_search=${search}` : ''

  return path => {
    navigate({
      pathname: path,
      search: query,
    })
  }
}
