import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import TextareaAutosize from 'react-textarea-autosize'

import { X } from 'components/Icons'
import { getColors, getFonts } from 'components/helpers/theme-selectors'
import { APP_BREAKPOINTS } from 'consts'

export const DeleteStyled = styled(X)``

export const CommmentContainer = styled(Flex)`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (min-width: ${APP_BREAKPOINTS.TMP_MAIN}px) {
    ${DeleteStyled} {
      opacity: 0;
    }

    &:hover {
      ${DeleteStyled} {
        opacity: 1;
      }
    }
  }
`

export const CommentInput = styled(({ isFocused, ref, ...rest }) => (
  <TextareaAutosize
    {...rest}
    {...{ ref }}
    onKeyPress={e => {
      if (e.key === 'Enter') {
        e.preventDefault()
        if (rest.onBlur) {
          rest.onBlur()
        }
        if (document.activeElement) {
          document.activeElement.blur()
        }
      }
    }}
  />
)).attrs({
  maxLength: 2000,
})`
  padding: 0;
  outline: none;
  width: 100%;
  font-family: ${props => getFonts(props).base};
  font-size: 14px;
  line-height: 20px;
  color: ${props => getColors(props).primary};
  resize: none;
  ${p => !p.isFocused && 'cursor: pointer'};

  ::placeholder {
    color: ${props => getColors(props).placeholder};
    opacity: 1;
    font-family: ${props => getFonts(props).base};
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    ::placeholder {
      color: ${props => getColors(props).primary};
    }
  }
`

export const CompleteIconContainer = styled(Flex)`
  cursor: pointer;
  align-items: center;
  flex-shrink: 0;
`
