import React from 'react'
import qs from 'qs'
import { generatePath, useNavigate } from 'react-router'

import { cn } from 'utils'
import { ROUTES } from 'consts'
import { FILTER_EVENTS, NAV_BAR_EVENTS } from 'services/analytics'

import ListItemSkeleton from 'components/ListItemSkeleton'
import { DefaultCuisinesIcon, DefaultCategoriesIcon, ICONS_MAP } from './Icons'
import { trackCategoriesDropdownEvent } from './utils'

const CategoriesDropdownList = ({
  isCuisinesSelected,
  selectedCategory,
  setSelectedCategory,
  categories,
  cuisines,
  isLoading,
}) => {
  const navigate = useNavigate()

  const DefaultIcon = isCuisinesSelected
    ? DefaultCuisinesIcon
    : DefaultCategoriesIcon

  const items = isCuisinesSelected ? cuisines : categories

  const handleCategoryClick = category => {
    if (selectedCategory?.id === category.id) {
      trackCategoriesDropdownEvent(NAV_BAR_EVENTS.SEE_ALL_CATEGORY_CLICKED, {
        category: category.name,
      })
      navigate(generatePath(ROUTES.PRODUCTS, { category: category.id }))
    } else {
      trackCategoriesDropdownEvent(NAV_BAR_EVENTS.CATEGORY_CLICKED, {
        category: category.name,
      })
      setSelectedCategory(category)
    }
  }

  const handleCuisineClick = cuisine => {
    trackCategoriesDropdownEvent(FILTER_EVENTS.clickedCuisine, {
      cuisine: cuisine.name,
    })

    const queryParams = qs.stringify(
      {
        filters: {
          cuisineFeatures: [cuisine.id],
        },
      },
      { encode: false, arrayFormat: 'brackets' },
    )

    navigate(`${generatePath(ROUTES.PRODUCTS)}?${queryParams}`)
  }

  const handleItemClick = item => {
    if (isCuisinesSelected) {
      handleCuisineClick(item)
    } else {
      handleCategoryClick(item)
    }
  }

  return (
    <div
      className="grid self-start gap-x-6 gap-y-1 grid-cols-[repeat(2,324px)]"
      data-test-id="item-list"
    >
      {isLoading ? (
        <>
          <ListItemSkeleton />
          <ListItemSkeleton />
        </>
      ) : (
        items.map(item => {
          const { id, name, productCount } = item
          const IconComponent = ICONS_MAP[id] || DefaultIcon

          return (
            <button
              type="button"
              key={id}
              onClick={() => handleItemClick(item)}
              className={cn(
                'py-2 px-3 flex items-center gap-3 rounded-lg hover:bg-grey-150 duration-300 ease-out',
                selectedCategory?.id === id &&
                  'group active-btn bg-green-600 hover:bg-green-500',
              )}
              data-test-id={`item-${id}`}
            >
              {IconComponent && <IconComponent />}
              <span className="flex-1 text-start text-label-13md overflow-hidden text-ellipsis whitespace-nowrap group-[.active-btn]:text-white">
                {name}
              </span>

              <span className="text-label-11 text-grey-800 group-[.active-btn]:text-white">
                {productCount}
              </span>
            </button>
          )
        })
      )}
    </div>
  )
}

export default CategoriesDropdownList
