import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

import { ROUTES } from 'consts'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { NAV_BAR_EVENTS } from 'services/analytics'
import CategoryTab from 'components/CategoryTab'
import TwButton, { BUTTON_VARIANTS } from 'components/Button/TwButton'

import { trackCategoriesDropdownEvent } from './utils'
import messages from './messages'

const CategoriesDropdownHeader = ({
  isCuisinesSelected,
  setCuisinesSelected,
  setSelectedCategory,
}) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const userData = useSelector(userDataSelector)

  const handleSelectCuisines = () => {
    trackCategoriesDropdownEvent(NAV_BAR_EVENTS.CUISINES_CLICKED)

    setCuisinesSelected(true)
    setSelectedCategory(null)
  }

  const handleSelectCategories = () => {
    trackCategoriesDropdownEvent(NAV_BAR_EVENTS.CATEGORIES_CLICKED)
    setCuisinesSelected(false)
  }

  const handleMainPageClick = () => {
    trackCategoriesDropdownEvent(NAV_BAR_EVENTS.MAIN_PAGE_CLICKED)
    navigate(ROUTES.CATEGORIES)
  }

  const handleAllProductsClick = () => {
    trackCategoriesDropdownEvent(NAV_BAR_EVENTS.ALL_PRODUCTS_CLICKED)
    navigate(ROUTES.ALL_PRODUCTS)
  }

  return (
    <div className="pb-4 flex justify-between items-start border-solid border-b border-grey-300">
      <div className="flex gap-0.5 mt-[5px]">
        <CategoryTab
          onClick={handleSelectCategories}
          className="px-4"
          title={formatMessage(messages.title)}
          active={!isCuisinesSelected}
        />
        {userData?.offerDetails?.hasCuisines && (
          <CategoryTab
            onClick={handleSelectCuisines}
            className="px-4"
            title={formatMessage(messages.cuisines)}
            active={isCuisinesSelected}
            showNewBadge
          />
        )}
      </div>
      <div className="flex gap-0.5 items-center">
        <TwButton
          variant={BUTTON_VARIANTS.tertiary}
          onClick={handleAllProductsClick}
          className="text-label-13md"
        >
          {formatMessage(messages.allProducts)}
        </TwButton>
        <div className="w-px h-6 bg-grey-300" />
        <TwButton
          onClick={handleMainPageClick}
          variant={BUTTON_VARIANTS.tertiary}
          className="text-label-13md"
        >
          {formatMessage(messages.mainPageLink)}
        </TwButton>
      </div>
    </div>
  )
}

export default CategoriesDropdownHeader
