import React from 'react'

import theme from 'theme'

const Promo = ({
  primaryColor = theme.colors.primary,
  secondaryColor = theme.colors.gray5,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_37307_182115)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4C1.89543 4 1 4.89543 1 6V8.4C1 8.73137 1.27133 8.99374 1.59617 9.05924C2.96742 9.33572 4 10.5473 4 12C4 13.4527 2.96742 14.6643 1.59617 14.9408C1.27134 15.0063 1 15.2686 1 15.6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V15.6C23 15.2686 22.7287 15.0063 22.4038 14.9408C21.0326 14.6643 20 13.4527 20 12C20 10.5473 21.0326 9.33572 22.4038 9.05924C22.7287 8.99374 23 8.73137 23 8.4V6C23 4.89543 22.1046 4 21 4H3Z"
        fill={secondaryColor}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.49959 8C9.10177 8 8.72024 8.15804 8.43893 8.43934C8.15763 8.72064 7.99959 9.10218 7.99959 9.5C7.99959 9.89782 8.15763 10.2794 8.43893 10.5607C8.72024 10.842 9.10177 11 9.49959 11C9.89742 11 10.2789 10.842 10.5603 10.5607C10.8416 10.2794 10.9996 9.89782 10.9996 9.5C10.9996 9.10218 10.8416 8.72064 10.5603 8.43934C10.2789 8.15804 9.89742 8 9.49959 8ZM14.2926 8.293L8.29259 14.293C8.19708 14.3852 8.1209 14.4956 8.06849 14.6176C8.01608 14.7396 7.9885 14.8708 7.98734 15.0036C7.98619 15.1364 8.01149 15.2681 8.06177 15.391C8.11205 15.5139 8.18631 15.6255 8.2802 15.7194C8.37409 15.8133 8.48574 15.8875 8.60864 15.9378C8.73154 15.9881 8.86321 16.0134 8.99599 16.0123C9.12877 16.0111 9.25999 15.9835 9.382 15.9311C9.504 15.8787 9.61435 15.8025 9.70659 15.707L15.7066 9.707C15.8888 9.5184 15.9895 9.2658 15.9873 9.0036C15.985 8.7414 15.8798 8.49059 15.6944 8.30518C15.509 8.11977 15.2582 8.0146 14.996 8.01233C14.7338 8.01005 14.4812 8.11084 14.2926 8.293ZM14.4996 13C14.1018 13 13.7202 13.158 13.4389 13.4393C13.1576 13.7206 12.9996 14.1022 12.9996 14.5C12.9996 14.8978 13.1576 15.2794 13.4389 15.5607C13.7202 15.842 14.1018 16 14.4996 16C14.8974 16 15.2789 15.842 15.5603 15.5607C15.8416 15.2794 15.9996 14.8978 15.9996 14.5C15.9996 14.1022 15.8416 13.7206 15.5603 13.4393C15.2789 13.158 14.8974 13 14.4996 13Z"
      fill={primaryColor}
    />
    <defs>
      <clipPath id="clip0_37307_182115">
        <rect width="22" height="16" fill="white" transform="translate(1 4)" />
      </clipPath>
    </defs>
  </svg>
)

export default Promo
