import React from 'react'

import theme from 'theme'

const Marketing = ({
  primaryColor = theme.colors.primary,
  secondaryColor = theme.colors.gray5,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.8401 3H6.96236C7.27709 3 7.51087 3.2633 7.4426 3.54086L5.99881 9.41053C5.77037 10.3392 4.8586 11 3.80555 11H3.60079C2.16798 11 1.15624 9.73184 1.60933 8.50382C1.65121 8.3903 1.70196 8.27962 1.76119 8.1726L4.08005 3.98273C4.41338 3.38045 5.09476 3 5.8401 3ZM15.5973 3H13.971C13.4276 3 12.9871 3.39797 12.9871 3.88889V8.96169C12.9871 10.0874 13.9972 11 15.2433 11C16.6374 11 17.6979 9.86901 17.4687 8.6266L16.5678 3.74276C16.4887 3.31414 16.0782 3 15.5973 3Z"
      fill={secondaryColor}
    />
    <path
      d="M11.029 3H9.40275C8.92178 3 8.5113 3.31414 8.43223 3.74276L7.53126 8.6266C7.30205 9.86901 8.36255 11 9.75673 11C11.0028 11 12.0129 10.0874 12.0129 8.96169V3.88889C12.0129 3.39797 11.5724 3 11.029 3Z"
      fill={secondaryColor}
    />
    <path
      d="M19.1599 3H18.0376C17.7229 3 17.4891 3.2633 17.5574 3.54086L19.0012 9.41053C19.2296 10.3392 20.1414 11 21.1945 11H21.3992C22.832 11 23.8438 9.73184 23.3907 8.50382C23.3488 8.3903 23.298 8.27962 23.2388 8.1726L20.92 3.98273C20.5866 3.38045 19.9052 3 19.1599 3Z"
      fill={secondaryColor}
    />
    <path
      d="M20.5012 12.9686C20.775 13.0046 21 13.2239 21 13.5V19.0001C21 20.1047 20.1045 21.0002 18.9999 21.0001L16 21C15.4477 21 15 20.5523 15 20V16.9998C15 16.4475 14.5523 15.9998 14 15.9998H11C10.4477 15.9998 10 16.4475 10 16.9998V20C10 20.5523 9.5523 21 9.00003 21L6.00007 21.0001C4.89547 21.0002 4 20.1047 4 19.0001V13.5C4 13.2239 4.22501 13.0046 4.4988 12.9686C5.22499 12.8732 5.78136 12.564 6.32265 12.095C6.53015 11.9152 6.84092 11.9154 7.0567 12.0852C7.7817 12.6556 8.69835 12.9998 9.70673 12.9998C10.6193 12.9998 11.4654 12.715 12.161 12.2295C12.3633 12.0883 12.6363 12.0883 12.8386 12.2295C13.5342 12.715 14.3803 12.9998 15.2929 12.9998C16.3013 12.9998 17.218 12.6556 17.943 12.0852C18.1587 11.9154 18.4695 11.9152 18.677 12.095C19.2184 12.564 19.7749 12.8732 20.5012 12.9686Z"
      fill={primaryColor}
    />
  </svg>
)

export default Marketing
