import { makeMessages, makeTranslation } from 'utils'

const messages = {
  clear: { id: 'common.labels.clear' },
  lackOfProducts: { id: 'notFound.lackOfProducts' },
  readMore: 'readMore',
  collapse: 'collapse',
}

export default makeMessages(messages, makeTranslation('campaign'))
