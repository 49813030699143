import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useSearchParams } from 'react-router'

import { cn } from 'utils'
import { getAuthToken } from 'containers/Auth/tokenStorage'
import TwSearch from 'components/TwSearch'

import { ArrowDownV2 as ArrowIcon } from 'components/Icons'
import { getPublicCategories, useKnowledgeBaseMenuNavigate } from '../utils'
import InfoIcon from './assets/info-icon.svg'
import Item from './Item'
import messages from '../messages'
import ListWithLoader from './ListWithLoader'

const CategoriesList = ({
  categories,
  className,
  fetchEntries,
  isLoading,
  isFiltered,
  isInitialized,
  faqPagePath,
}) => {
  const { formatMessage } = useIntl()
  const navigate = useKnowledgeBaseMenuNavigate()
  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()
  const searchQuery = searchParams.get('kb_search')
  const [searchValue, setSearchValue] = useState(searchQuery || '')

  const token = getAuthToken()
  const categoriesList = useMemo(
    () => {
      if (!token) return getPublicCategories(categories)

      return categories
    },
    [token, categories],
  )

  useEffect(() => {
    setSearchValue(searchQuery || '')
  }, [])

  useEffect(
    () => {
      fetchEntries(searchQuery)
    },
    [searchParams],
  )

  const handleSearch = useCallback(
    value => {
      if (value) {
        setSearchParams({
          kb_search: value,
        })
      } else {
        setSearchParams({})
      }
    },
    [location],
  )

  const handleClearSearch = () => {
    setSearchValue('')
    setSearchParams({})
  }

  const validateSearch = useCallback(value => value.length >= 3, [])

  return (
    <div
      className={cn('flex flex-col gap-3', className)}
      data-test-id="categories-list"
    >
      <TwSearch
        className="w-full"
        placeholder={formatMessage(messages.searchPlaceholder)}
        validateSearch={validateSearch}
        onSearch={handleSearch}
        value={searchValue}
        setValue={setSearchValue}
      />
      {token && (
        <Item
          title={
            <>
              {formatMessage(messages.mainTitle)}
              <div className="w-6 h-full flex items-center justify-center ml-auto">
                <ArrowIcon
                  className={cn(
                    '[&_path]:stroke-blue-900 -rotate-90',
                    isFiltered && '[&_path]:stroke-grey-500',
                  )}
                />
              </div>
            </>
          }
          testId={formatMessage(messages.mainTitle)}
          icon={InfoIcon}
          onClick={() => navigate(faqPagePath)}
          isHighlighted={faqPagePath === location.pathname}
        />
      )}
      <ListWithLoader
        categoriesList={categoriesList}
        isLoading={isLoading}
        isFiltered={isFiltered}
        clearSearch={handleClearSearch}
        isInitialized={isInitialized}
      />
    </div>
  )
}

export default CategoriesList
