import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'

import useHasSeenElement from 'hooks/useHasSeenElement'
import {
  useLazyGetRecommendationsQuery,
  useGetRecommendationsState,
} from 'containers/Products/rtkApi'
import ProductsCarousel from 'components/ProductsCarousel'

import messages from './messages'

const RecommendedBox = ({
  categoryId,
  groupId,
  subgroupId,
  pageName,
  itemListId,
  itemListName,
  cuisineFeatures,
  className,
  fadeClassName,
}) => {
  const { formatMessage } = useIntl()
  const [carouselRef, hasSeenCarousel, setHasSeenCarousel] = useHasSeenElement()

  const [fetchRecommendations] = useLazyGetRecommendationsQuery()
  const {
    data: {
      products: recommendations = [],
      meta: { experiment: { id: itemModelId } = {}, attributionToken } = {},
    } = {},
    isFetching: isRecommendationsFetching,
    isUninitialized: isRecommendationsUninitialized,
  } = useGetRecommendationsState({
    categoryId,
    groupId,
    subgroupId,
    pageName,
    cuisineFeatures,
  })

  useEffect(
    () => {
      if (hasSeenCarousel) {
        fetchRecommendations({
          categoryId,
          groupId,
          subgroupId,
          pageName,
          cuisineFeatures,
        })
      }
    },
    [
      categoryId,
      groupId,
      subgroupId,
      pageName,
      hasSeenCarousel,
      cuisineFeatures,
    ],
  )

  useEffect(
    () => {
      setHasSeenCarousel(false)
    },
    [
      categoryId,
      groupId,
      subgroupId,
      pageName,
      setHasSeenCarousel,
      cuisineFeatures,
    ],
  )

  return (
    <ProductsCarousel
      ref={carouselRef}
      isLoading={isRecommendationsFetching || isRecommendationsUninitialized}
      products={recommendations}
      itemModelId={itemModelId}
      attributionToken={attributionToken}
      itemListName={itemListName}
      itemListId={itemListId}
      title={formatMessage(messages.title)}
      testId="recommended-section"
      className={className}
      fadeClassName={fadeClassName}
    />
  )
}

export default RecommendedBox
