import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation, useNavigate } from 'react-router'
import { useIntl } from 'react-intl'

import { ROUTES } from 'consts'
import { userDataSelector } from 'containers/UserInfo/selectors'
import {
  trackSimpleEvent,
  NAV_BAR_EVENTS,
  FILTER_EVENTS,
  ALL_PROMO_ORIGINS,
} from 'services/analytics'

import { ArrowDownV2, ArrowRightV2 } from 'components/Icons'
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popup'
import Categories from 'components/CategoriesDropdown'
import { HEADER_DROPDOWN_TRANSITIONS } from 'components/Popup/transitions'

import { DESKTOP_HEIGHT, NavButton, NavigationWrapper } from './styles'
import messages from './messages'

const NavBar = ({ toggleMobileCategories, isMobile }) => {
  const userData = useSelector(userDataSelector)
  const navigate = useNavigate()
  const location = useLocation()
  const [isCategoriesOpen, setCategoriesOpen] = useState(false)
  const { formatMessage } = useIntl()

  const isPromotionsBtnVisible =
    userData.offerDetails?.hasActiveClearances ||
    userData.offerDetails?.hasActivePromotions

  const isCategoriesRoute = [
    ROUTES.PRODUCTS,
    ROUTES.PRODUCT_DETAILS,
    ROUTES.SEARCH_RESULTS,
  ].some(route => matchPath(route, location.pathname))

  useEffect(
    () => {
      if (isCategoriesOpen) {
        setCategoriesOpen(false)
      }
    },
    [location],
  )

  const categoriesClickHandler = () => {
    if (isMobile) {
      toggleMobileCategories(true)
      trackSimpleEvent(NAV_BAR_EVENTS.CATALOG_CLICKED)
    } else {
      setCategoriesOpen(!isCategoriesOpen)
      !isCategoriesOpen && trackSimpleEvent(NAV_BAR_EVENTS.CATALOG_CLICKED)
    }
  }

  return (
    <NavigationWrapper>
      {isMobile && (
        <NavButton
          data-test-id="home"
          isActive={matchPath(ROUTES.CATEGORIES, location.pathname)}
          onClick={() => {
            navigate(ROUTES.CATEGORIES)
          }}
        >
          {formatMessage(messages.home)}
        </NavButton>
      )}
      {isPromotionsBtnVisible && (
        <NavButton
          data-test-id="promo"
          isActive={matchPath(ROUTES.PROMOTIONS.ROOT, location.pathname)}
          onClick={() => {
            navigate(ROUTES.PROMOTIONS.ROOT)
            trackSimpleEvent(FILTER_EVENTS.clickedPromotions, {
              origin: ALL_PROMO_ORIGINS.NAV,
            })
          }}
        >
          {formatMessage(messages.promo)}
        </NavButton>
      )}
      <Popover
        disabled={isMobile}
        disableArrow
        open={isCategoriesOpen}
        setOpen={setCategoriesOpen}
        onClickOutside={newState => setCategoriesOpen(newState)}
      >
        <PopoverContent
          style={{
            position: 'fixed',
            left: '50%',
            transform: 'translateX(-50%)',
            top: DESKTOP_HEIGHT + 4,
          }}
          transitionContainerStyle={{
            transformOrigin: 'top center',
          }}
          transitions={HEADER_DROPDOWN_TRANSITIONS}
          withOverlay
        >
          <Categories />
        </PopoverContent>
        <PopoverTrigger asChild>
          <NavButton
            data-test-id="categories"
            isActive={isCategoriesRoute || isCategoriesOpen}
            isDropdownActive={isCategoriesOpen}
            onClick={categoriesClickHandler}
          >
            {formatMessage(messages.categories)}
            {isMobile ? <ArrowRightV2 /> : <ArrowDownV2 />}
          </NavButton>
        </PopoverTrigger>
      </Popover>

      <NavButton
        data-test-id="templates"
        isActive={matchPath(ROUTES.TEMPLATES, location.pathname)}
        onClick={() => {
          navigate(ROUTES.TEMPLATES)
          trackSimpleEvent(NAV_BAR_EVENTS.TEMPLATES_CLICKED)
        }}
      >
        {formatMessage(messages.templates)}
      </NavButton>
    </NavigationWrapper>
  )
}

export default NavBar
