import React from 'react'

import { cn } from 'utils'

const SkeletonBlock = ({ className, ...rest }) => (
  <div
    className={cn(
      'w-[300px] h-10 rounded-lg bg-linear-to-l from-grey-100 to-grey-350',
      className,
    )}
    {...rest}
  />
)

export default SkeletonBlock
