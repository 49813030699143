import React, { useState, useEffect } from 'react'
import { generatePath, useMatch } from 'react-router'

import { ROUTES } from 'consts'
import { cn } from 'utils'
import { ArrowDownV2 as ArrowIcon } from 'components/Icons'
import { useKnowledgeBaseMenuNavigate } from 'components/KnowledgeBase/utils'
import Item from '../Item'

const CategoryItem = ({ category, isFiltered }) => {
  const { articles, title, icon, categoryId } = category
  const navigate = useKnowledgeBaseMenuNavigate()
  const { params = {} } = useMatch(ROUTES.KNOWLEDGE_BASE) || {}

  const [isExpanded, setIsExpanded] = useState(false)

  // set parent category expanded if enters nested article
  useEffect(
    () => {
      if (params.article && params.category === categoryId) {
        setIsExpanded(true)
      }
    },
    [params, categoryId],
  )

  const navigateToEntry = article =>
    navigate(
      generatePath(ROUTES.KNOWLEDGE_BASE, {
        category: categoryId,
        article,
      }),
    )

  const isCategorySelected = !params.article && params.category === categoryId

  return (
    <div
      data-test-id={`kb-list-cat${
        isExpanded || isFiltered ? '-expanded' : ''
      }#${title}`}
      className="flex flex-col gap-[2px]"
    >
      <Item
        title={title}
        icon={icon}
        isHighlighted={isCategorySelected}
        isDisabled={isFiltered}
        onClick={() => navigateToEntry()}
      >
        <div className="h-6 w-[1px] mx-[1px] bg-grey-300" />
        <button
          data-test-id={`kb-cat-expand-btn#${title}`}
          type="button"
          className={cn(
            'flex px-5 py-3 rounded-e-lg hover:bg-grey-200',
            isCategorySelected && 'hover:bg-grey-200',
            isFiltered && 'hover:bg-transparent',
          )}
          onClick={() => setIsExpanded(state => !state)}
          disabled={isFiltered}
        >
          <div
            className={cn(
              'transition duration-300 ease-out',
              (isExpanded || isFiltered) && 'rotate-180',
            )}
          >
            <ArrowIcon
              className={cn(
                '[&_path]:stroke-blue-900',
                isFiltered && '[&_path]:stroke-grey-500',
              )}
            />
          </div>
        </button>
      </Item>
      {(isExpanded || isFiltered) &&
        articles.map(({ title: artTitle, articleId }) => {
          const isArticleSelected = params.article === articleId

          return (
            <button
              key={artTitle}
              data-test-id={`kb-list-item#${artTitle}`}
              type="button"
              disabled={isArticleSelected}
              className={cn(
                'flex pl-14 pr-4 py-2 rounded-lg hover:bg-grey-200 min-h-10 items-center',
                (isFiltered || isArticleSelected) && 'bg-grey-200',
                isFiltered && !isArticleSelected && 'hover:bg-[#e5e8ee]',
              )}
              onClick={() => navigateToEntry(articleId)}
            >
              <span
                className={cn(
                  'text-12 text-left',
                  isFiltered && 'text-green-700',
                  isArticleSelected && 'text-blue-900',
                )}
              >
                {artTitle}
              </span>
            </button>
          )
        })}
    </div>
  )
}

export default CategoryItem
