import React from 'react'
import { useLocation } from 'react-router'

import NotFoundPage from 'views/NotFoundPage'
import LoaderFullHeight from 'components/LoaderFullHeight'

const Redirect = () => {
  const { state } = useLocation()

  if (!state) {
    return <NotFoundPage />
  }

  return <LoaderFullHeight />
}

export default Redirect
