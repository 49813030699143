import React from 'react'
import { useIntl } from 'react-intl'

import {
  Loader,
  NoResults as NoResultsIcon,
  XNormal as ResetIcon,
} from 'components/Icons'
import NoResults from 'components/NoResultsV2'

import CategoryItem from './CategoryItem'
import messages from '../messages'

const ListWithLoader = ({
  isLoading,
  categoriesList,
  isFiltered,
  clearSearch,
  isInitialized,
}) => {
  const { formatMessage } = useIntl()

  if (isLoading) {
    return (
      <div className="flex justify-center w-full py-[100px]">
        <Loader className="animate-reverse-spin" />
      </div>
    )
  }

  if (isInitialized && isFiltered && !categoriesList.length) {
    return (
      <div className="py-10">
        <NoResults
          text={formatMessage(messages.noResults)}
          icon={<NoResultsIcon />}
          btnIcon={<ResetIcon />}
          btnText={formatMessage(messages.clear)}
          handleBtnClick={clearSearch}
        />
      </div>
    )
  }

  return categoriesList?.map(category => (
    <CategoryItem
      key={category.title}
      category={category}
      isFiltered={isFiltered}
    />
  ))
}

export default ListWithLoader
