import React, { useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Flex } from '@rebass/grid'
import _isEmpty from 'lodash/isEmpty'
import { compose, omit } from 'lodash/fp'

import qs from 'qs'
import withModal from 'hocs/withModal'
import { withSpinner } from 'hocs'
import { useQueryFilters, useOfMaxWidth } from 'hooks'
import {
  PRODUCTS_REDUCER_NAME,
  PRODUCT_ITEMS_REDUCER_NAME,
} from 'containers/Products'
import productsActions from 'containers/Products/actions'
import {
  PROMO_GROUP_REDUCER_NAME,
  PROMOS_REDUCER_NAME,
} from 'containers/Promotions/consts'
import ProductBox from 'components/Product/ProductBox'
import {
  withPageView,
  SCREENS,
  ITEM_LIST_NAMES,
  ITEM_LIST_IDS,
} from 'services/analytics'
import useProductsSectionName from 'containers/Products/Filters/useProductsSectionName'
import {
  REPLACEMENTS_MODAL,
  REPLACEMENTS_REDUCER_NAME,
} from 'containers/Products/Replacements/consts'
import { APP_BREAKPOINTS } from 'consts'
import Pagination from 'components/Pagination'
import {
  makePaginationSelector,
  makeProductsSelector,
  selectFinished,
} from 'containers/Products/selectors'
import { getFiltersActions } from 'containers/Products/Filters/actions'
import GroupButtonsComponent from 'views/Products/Header/GroupButtons'
import useChangedLocationModal from 'hooks/useChangedLocationModal'
import Replacements from './Replacements'
import Recommendations from './Recommendations'

import { FiltersContainer } from './styledComponents/Layout'
import {
  getUrlParams,
  getValidPage,
  isAnyFilterSelected,
  stringifyQueryParams,
} from './utils'
import NoResults from './NoResults'
import Header from './Header'
import { FooterNav } from './styledComponents/FooterNav'
import CatalogFilters from './Filters'
import FiltersPagination from './Filters/components/FiltersPagination'
import {
  InnerContainer,
  ProductsContent,
  StyledDiv,
  MobileProductPaginationWrapper,
} from './styledComponents'

// TODO: refactor the view
// split this view into smaller chunks, utilize hooks to extract some logic from render method
const Products = () => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const {
    selectedFilters,
    selectedFiltersParams,
    searchQuery,
  } = useQueryFilters()
  const filtersRequestParamsStrRef = useRef(null)
  const productsRequestParamsStrRef = useRef(null)
  const filtersRef = useRef(null)

  const dispatch = useDispatch()
  const products = useSelector(makeProductsSelector)
  const { totalPages, totalCount = 0 } = useSelector(makePaginationSelector)
  const isFinished = useSelector(selectFinished)

  const sectionName = useProductsSectionName()
  const urlParams = getUrlParams(params, location.search)
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const isFilteringApplied = isAnyFilterSelected(selectedFilters)
  const isEmptyFiltering = !totalCount && isFilteringApplied

  useChangedLocationModal({ isReady: isFinished })

  const handlePageChange = nextPage => {
    const current = getValidPage({
      current: nextPage,
      totalPages,
    })

    const queryParams = qs.stringify(
      {
        search: searchQuery,
        current,
        filters: selectedFiltersParams,
      },
      { encode: false, arrayFormat: 'brackets' },
    )

    navigate({ search: queryParams })
  }

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 0,
  })

  const handleFiltersClear = useCallback(
    () => {
      const newQueries = qs.stringify(
        {
          ...queryParams,
          current: null,
          filters: null,
        },
        { encode: false, arrayFormat: 'brackets', skipNulls: true },
      )

      navigate({ search: newQueries }, { replace: true })
    },
    [queryParams],
  )

  useEffect(
    () => {
      const productsRequestParams = {
        ...urlParams,
        ...selectedFiltersParams,
      }
      const productsRequestParamsStr = stringifyQueryParams(
        productsRequestParams,
      )

      if (productsRequestParamsStr === productsRequestParamsStrRef.current) {
        // no need to refetch products or filters if param values did not change
        return
      }

      productsRequestParamsStrRef.current = productsRequestParamsStr
      // pagination should not affect available filters, skipping it to see if we need to refetch filters
      const urlParamsExceptPagination = omit(['current', 'pageSize'], urlParams)
      const filtersRequestParamsStr = stringifyQueryParams({
        ...urlParamsExceptPagination,
        ...selectedFiltersParams,
      })
      if (filtersRequestParamsStr !== filtersRequestParamsStrRef.current) {
        filtersRequestParamsStrRef.current = filtersRequestParamsStr
        dispatch(
          getFiltersActions.delta({
            ...urlParamsExceptPagination,
            selectedFiltersParams,
          }),
        )
      }

      const filtersStr = stringifyQueryParams(selectedFiltersParams)
      if (filtersStr !== filtersRef.current) {
        if (urlParams.current !== 1 && filtersRef.current !== null) {
          // redirect user to 1st page when:
          // - user not on 1st page
          // - filters changed,
          // - previous filters were existing (even if empty)

          handlePageChange(1)
          filtersRef.current = filtersStr
          return
        }

        filtersRef.current = filtersStr
      }

      dispatch(productsActions.delta(productsRequestParams))
    },
    [urlParams, selectedFiltersParams],
  )

  useEffect(() => () => dispatch(productsActions.clear()), [])

  const ContentWrapper = totalCount ? ProductsContent : Flex
  // extra check for search catalog view due to filtering unmounting and params persistance
  const isSearchFiltering = isFilteringApplied || !!queryParams.filters

  const [itemListId, itemListName] = searchQuery
    ? [ITEM_LIST_IDS.SEARCH_RESULTS, ITEM_LIST_NAMES.SEARCH_RESULTS]
    : [ITEM_LIST_IDS.CATALOG, ITEM_LIST_NAMES.CATALOG]

  return (
    (!_isEmpty(products) || isFinished) && (
      <div className="flex flex-col w-full py-6 md:py-8 *:px-6 *:md:px-8">
        <Header />

        {!totalCount && !isSearchFiltering && !!searchQuery ? (
          <NoResults />
        ) : (
          <>
            <div>
              <GroupButtonsComponent />
              <InnerContainer>
                <StyledDiv>
                  <FiltersContainer {...{ isMobile }}>
                    <CatalogFilters
                      current={urlParams.current}
                      onPageChange={handlePageChange}
                      handleFiltersClear={handleFiltersClear}
                      selectedFilters={selectedFilters}
                      selectedFiltersParams={selectedFiltersParams}
                      sectionName={sectionName}
                      queryParams={queryParams}
                      totalPages={totalPages}
                      totalCount={totalCount}
                    />
                  </FiltersContainer>
                  {!!totalCount && (
                    <MobileProductPaginationWrapper>
                      <FiltersPagination
                        current={urlParams.current}
                        onPageChange={handlePageChange}
                        {...{ totalCount, totalPages }}
                      />
                    </MobileProductPaginationWrapper>
                  )}
                  <ContentWrapper>
                    {totalCount ? (
                      products.map((product, index) => (
                        <ProductBox
                          isCatalog
                          refetchDataCallback={() => {
                            dispatch(
                              getFiltersActions.delta({
                                ...urlParams,
                                ...selectedFiltersParams,
                              }),
                            )
                            dispatch(
                              productsActions.delta({
                                ...urlParams,
                                ...selectedFiltersParams,
                              }),
                            )
                          }}
                          itemListName={itemListName}
                          itemListId={itemListId}
                          key={product.id}
                          {...product}
                          {...{ index, product }}
                        />
                      ))
                    ) : (
                      <NoResults
                        handleFiltersClear={handleFiltersClear}
                        isFilteringApplied
                      />
                    )}
                    {products.length <= 3 && (
                      <>
                        <div />
                        <div />
                      </>
                    )}
                  </ContentWrapper>
                </StyledDiv>
                {!!totalCount && (
                  <FooterNav>
                    <Pagination
                      current={urlParams.current}
                      onChange={handlePageChange}
                      {...{ totalPages }}
                    />
                  </FooterNav>
                )}
              </InnerContainer>
            </div>
            {!searchQuery && (
              <Recommendations
                isEmptyListing={isEmptyFiltering}
                cuisineFeatures={selectedFiltersParams.cuisineFeatures}
              />
            )}
          </>
        )}
      </div>
    )
  )
}

export default compose(
  withPageView(SCREENS.PRODUCTS),
  withModal(Replacements, REPLACEMENTS_MODAL),
  withSpinner([
    [PRODUCTS_REDUCER_NAME, PRODUCT_ITEMS_REDUCER_NAME],
    [PRODUCTS_REDUCER_NAME, REPLACEMENTS_REDUCER_NAME],
    [PROMOS_REDUCER_NAME, PROMO_GROUP_REDUCER_NAME],
  ]),
)(Products)
