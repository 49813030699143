import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useIntl } from 'react-intl'

import { trackSortingClick } from 'services/analytics'
import Dropdown from 'components/Filters/Dropdown'
import { SORT_BY_TYPES, DEFAULT_SORT_BY_TYPES } from 'containers/Templates'
import TwButton, { BUTTON_VARIANTS } from 'components/Button/TwButton'
import { PopoverClose } from 'components/Popup'

import messages from './messages'

const TemplateSorting = ({
  filteringSettings,
  updateFilteringSettings,
  isTemplateAutomatic,
  type,
}) => {
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const [currentSortingObj, setCurrentSortingObj] = useState({})

  useEffect(
    () => {
      if (filteringSettings.sortBy) {
        const newObj = sortingConfig.find(
          option => option.type === filteringSettings.sortBy,
        )
        setCurrentSortingObj(newObj)
      } else {
        setCurrentSortingObj(
          isTemplateAutomatic
            ? systemTemplateOptions[0]
            : userTemplateOptions[0],
        )
      }
    },
    [filteringSettings, isTemplateAutomatic],
  )

  const { NAME, PURCHASE_DATE } = SORT_BY_TYPES
  const { SYSTEM, USER } = DEFAULT_SORT_BY_TYPES

  const handleSortingSelection = value => {
    if (value !== filteringSettings.sortBy) {
      updateFilteringSettings({
        sortBy: value,
      })
    }
  }

  const sortByName = {
    title: 'name',
    type: NAME,
    handleClick: () => handleSortingSelection(NAME),
  }

  const systemTemplateOptions = [
    {
      title: 'default',
      type: SYSTEM,
      handleClick: () => handleSortingSelection(SYSTEM),
    },
    {
      title: 'purchaseDate',
      type: PURCHASE_DATE,
      handleClick: () => handleSortingSelection(PURCHASE_DATE),
    },
    sortByName,
  ]

  const userTemplateOptions = [
    {
      title: 'createdAt',
      type: USER,
      handleClick: () => handleSortingSelection(USER),
    },
    sortByName,
  ]

  const sortingConfig = isTemplateAutomatic
    ? systemTemplateOptions
    : userTemplateOptions

  const dropdownTitle = currentSortingObj?.title
  const isSortingObj = Object.keys(currentSortingObj || {}).length

  if (isSortingObj) {
    return (
      <div className="flex lg:pr-2 lg:mr-14 lg:border-r lg:border-grey-300">
        <Dropdown
          title={formatMessage(messages[dropdownTitle])}
          onDropdownShow={() => trackSortingClick(id, type)}
          testId="template-sorting-btn"
          width={220}
        >
          <div className="flex flex-col p-4">
            {sortingConfig.map(({ title, handleClick }) => (
              <PopoverClose className="flex">
                <TwButton
                  variant={BUTTON_VARIANTS.tertiary}
                  className="hover:bg-grey-150 flex flex-1 justify-start"
                  onClick={handleClick}
                  data-test-id={`template-sortBy-${title}`}
                  key={title}
                >
                  <span className="text-13 leading-6">
                    {formatMessage(messages[title])}
                  </span>
                </TwButton>
              </PopoverClose>
            ))}
          </div>
        </Dropdown>
      </div>
    )
  }

  return null
}

export default TemplateSorting
