import React from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import TextTooltip from 'components/Tooltip/TextTooltip'
import { FEED_MESSAGE_STYLING_CONFIG } from '../../consts'

import {
  ReadButton,
  TextWrapper,
  UnreadIndicator,
  Container,
  ActionsContainer,
  TitleContainer,
  TimeAgoText,
  IconBadge,
} from './styles'
import messages from '../messages'

const NotificationFeedItem = ({
  title,
  innerHtml,
  IconComponent,
  onReadClick,
  isRead,
  insertedAt,
  onClick,
  stylingType,
  testId,
}) => {
  const { formatMessage } = useIntl()

  const { bgColor, iconColors } = FEED_MESSAGE_STYLING_CONFIG[stylingType]

  return (
    <Container data-test-id={testId} $isRead={isRead} onClick={onClick}>
      <IconBadge $bgColor={bgColor}>
        <IconComponent
          primaryColor={iconColors.primary}
          secondaryColor={iconColors.secondary}
        />
      </IconBadge>
      <div className="flex flex-1">
        <TextWrapper>
          <TitleContainer>
            <h4>{title}</h4>
            <TimeAgoText>{dayjs(insertedAt).fromNow()}</TimeAgoText>
          </TitleContainer>
          <span
            dangerouslySetInnerHTML={{
              __html: innerHtml,
            }}
          />
        </TextWrapper>
        <ActionsContainer>
          {!isRead && (
            <TextTooltip
              placement="left"
              disableArrow
              content={formatMessage(messages.markAsRead)}
              offsetValues={{
                main: 4,
              }}
            >
              <ReadButton onClick={onReadClick} data-test-id="mark-as-read-btn">
                <UnreadIndicator />
              </ReadButton>
            </TextTooltip>
          )}
        </ActionsContainer>
      </div>
    </Container>
  )
}

export default NotificationFeedItem
