import { makeMessages, makeTranslation } from 'utils'

const messages = {
  help: 'help',
  privacyPolicy: 'privacyPolicy',
  termsOfUse: 'termsOfUse',
  provisioningTerms: 'provisioningTerms',
  companyName: 'companyName',
  emailSubscribeSuccessToast: {
    id: 'newsletter.emailSubscribeSuccessToast',
  },
  informationTitle: 'information.title',
  contactsTitle: 'contacts.title',
  contactsPlaceholder: 'contacts.placeholder',
  newsletterTitle: 'newsletter.title',
  appsTitle: 'apps.title',
  downloadFrom: 'apps.downloadFrom',
  appStore: 'apps.appStore',
  googlePlay: 'apps.googlePlay',
  newsletterCtaBtn: 'newsletterCtaBtn',
  newsletterEmailRequired: { id: 'validators.blank' },
  newsletterEmailInvalid: { id: 'validators.email' },
  knowledgeBase: { id: 'knowledgeBase.mainTitle' },
}

export default makeMessages(messages, makeTranslation('footer'))
