import React, { useMemo, useState } from 'react'
import { Flex } from '@rebass/grid'
import { FormattedMessage } from 'react-intl'
import { Field } from 'formik'
import { useSelector, useDispatch } from 'react-redux'

import { useOfMaxWidth } from 'hooks'
import theme from 'theme'
import { ALERT_TYPE } from 'components/Alert'
import { notifyAction } from 'containers/App/actions'
import ProductImage, {
  PRODUCT_IMAGE_SIZES,
} from 'components/Product/ProductImage'
import { TertiaryText } from 'components/Text'
import { APP_BREAKPOINTS } from 'consts'
import CheckboxV2 from 'components/Input/CheckboxV2'
import RadioV2 from 'components/Input/RadioV2'
import { isElasticStock } from 'utils'
import { cartProductDeliveryDateSelector } from 'containers/Cart/selectors'
import cartActions from 'containers/Cart/actions'
import { closeAllModals } from 'containers/App/actions/modal'
import templateMessages from 'components/Product/TemplateProductRow/messages'
import { useSelectItemTracking } from 'services/analytics'
import {
  REPLACEMENT_STATUS,
  getReplacementStatus,
  getProductGenericInfo,
  formatAmount,
} from 'components/Product/utils'
import { userDataSelector } from 'containers/UserInfo/selectors'

import productContainer from 'containers/components/Product/ButtonsContainer'
import { formatHumanDate, formatRegularDate } from '../Availability/utils'
import BrandAndReference from '../BrandAndReference'
import ProductName from '../ProductName'
import UnitPrice from '../UnitPrice'
import messages from '../messages'
import Availability, { AVAILABILITY_MODE } from '../Availability'

import PricingBlock from './PricingBlock'
import CtaBtn from './CtaBtn'
import {
  Container,
  ImageContainer,
  Content,
  PrimarySection,
  PricingSection,
  ReplacementsBtnContainer,
  UnitPriceContainer,
  AvailabilityContainer,
  FieldWrapper,
  RadioGroupWrapper,
} from './styles'
import cartMessages from './messages'

const ProductRowBoxMissing = ({
  product: { id, name, brand, active, baseUnitOfMeasureDescription, nonStock },
  product,
  productId,
  unitOfMeasure,
  formatMessage,
  quantityAdded,
  quantityMissing,
  getReplacements,
  onTrackGetReplacements,
  onTrackShiftDelivery,
  renderProductError,
  errorId,
  errorMessage,
  deleteCartItem,
  addRemoveCallback,
  isCartFlow,
  itemListId,
  itemListName,
  additionalEventParams,
  index,
}) => {
  const dispatch = useDispatch()
  const [isCtaDisabled, setIsCtaDisabled] = useState(false)
  const userData = useSelector(userDataSelector)
  const cartProductDeliveryDate = useSelector(
    cartProductDeliveryDateSelector(id),
  )
  const regularCartProductDD = cartProductDeliveryDate
    ? formatRegularDate(cartProductDeliveryDate)
    : ''
  const cartProductDDFormatted = cartProductDeliveryDate
    ? formatHumanDate({ date: cartProductDeliveryDate, formatMessage })
    : ''
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const { productPath, priceShown, unitOfMeasureObj } = getProductGenericInfo({
    product,
    unitOfMeasure,
  })
  const replacementStatus = getReplacementStatus({
    product,
    unitOfMeasureObj,
    currentUser: userData,
  })
  const outOfStock = replacementStatus === REPLACEMENT_STATUS.OUT_OF_STOCK
  const isElastic = isElasticStock({
    stock: unitOfMeasureObj.stock,
    nonStock: product.nonStock,
  })

  const [amountMissingFormatted, amountAddedFormatted] = useMemo(
    () => [
      formatAmount(unitOfMeasureObj, quantityAdded + quantityMissing),
      formatAmount(unitOfMeasureObj, quantityAdded),
    ],
    [unitOfMeasureObj, quantityAdded, quantityMissing],
  )

  const renderAvailabilityError = () =>
    active && (
      <AvailabilityContainer>
        <Availability
          product={product}
          unitOfMeasureObj={unitOfMeasureObj}
          mode={AVAILABILITY_MODE.TEXT}
        />
      </AvailabilityContainer>
    )
  const regularClosestDD = formatRegularDate(product.closestDeliveryTime)
  const closestDDFormatted = useMemo(
    () => formatHumanDate({ date: product.closestDeliveryTime, formatMessage }),
    [product.closestDeliveryTime],
  )

  const trackSelectItem = useSelectItemTracking({
    product,
    itemListId,
    itemListName,
    unitOfMeasure,
    additionalEventParams,
    index,
  })

  return (
    <>
      <Container data-test-id={`product-missing#${productId}`}>
        <ImageContainer>
          <ProductImage
            product={product}
            outOfStock={outOfStock}
            size={PRODUCT_IMAGE_SIZES.S}
          />
        </ImageContainer>

        <Content>
          <PrimarySection>
            <BrandAndReference brand={brand} id={productId} />
            <ProductName
              {...{ active, productPath, name }}
              onNameClick={trackSelectItem}
              eventParams={{ itemListId, itemListName }}
            />
          </PrimarySection>

          {renderProductError
            ? renderProductError({ product, errorId, errorMessage })
            : renderAvailabilityError()}

          <PricingSection>
            <UnitPriceContainer>
              <UnitPrice
                active={active}
                priceShown={priceShown}
                unitOfMeasureObj={unitOfMeasureObj}
                formatMessage={formatMessage}
                baseUnitOfMeasureDescription={baseUnitOfMeasureDescription}
              />

              <Flex>
                <PricingBlock
                  tiny={isMobile}
                  amount={amountMissingFormatted}
                  isAddedAmount
                  label={
                    isElastic && (
                      <FormattedMessage
                        {...messages.availableForDelivery}
                        values={{
                          date: closestDDFormatted,
                        }}
                      />
                    )
                  }
                />
                <PricingBlock
                  tiny={isMobile}
                  amount={amountAddedFormatted}
                  isError={!quantityAdded}
                  label={
                    isElastic && (
                      <FormattedMessage
                        {...messages.inCartOnDate}
                        values={{ date: cartProductDDFormatted }}
                      />
                    )
                  }
                />
              </Flex>
            </UnitPriceContainer>

            {!isCartFlow && (
              <ReplacementsBtnContainer>
                <CtaBtn
                  onDelete={() =>
                    deleteCartItem({
                      productId,
                      nonStock,
                      product,
                      unitOfMeasure,
                      successCallback: () => {
                        if (addRemoveCallback) {
                          addRemoveCallback({
                            removedFromCart: true,
                            unitOfMeasure,
                          })
                        }
                      },
                    })
                  }
                  onShiftDelivery={() => {
                    setIsCtaDisabled(true)
                    return dispatch(
                      cartActions.createDelta({
                        product,
                        unit: unitOfMeasure,
                        quantity: quantityAdded + quantityMissing,

                        suppressStockNotification: true,
                        suppressElasticStockCheck: true,
                        callback: () => {
                          setIsCtaDisabled(false)
                          dispatch(closeAllModals())
                          dispatch(
                            notifyAction({
                              message: (
                                <FormattedMessage
                                  {...templateMessages.addedItemToCartNotification}
                                />
                              ),
                              type: ALERT_TYPE.SUCCESS,
                            }),
                          )
                          onTrackShiftDelivery &&
                            onTrackShiftDelivery(
                              productId,
                              regularCartProductDD,
                              quantityAdded,
                              regularClosestDD,
                              quantityAdded + quantityMissing,
                            )

                          if (addRemoveCallback) {
                            addRemoveCallback({
                              unitOfMeasure,
                            })
                          }
                        },
                      }),
                    )
                  }}
                  onGetReplacements={() => {
                    getReplacements({
                      productId,
                      showInModal: true,
                    })
                    onTrackGetReplacements && onTrackGetReplacements(productId)
                  }}
                  isDisabled={isCtaDisabled}
                  {...{
                    product,
                    unitOfMeasureObj,
                    isElastic,
                    errorId,
                    quantityAdded,
                    quantityMissing,
                  }}
                  closestDDFormatted={closestDDFormatted}
                />
              </ReplacementsBtnContainer>
            )}
          </PricingSection>
        </Content>
      </Container>

      {isCartFlow &&
        (quantityAdded ? (
          <RadioGroupWrapper role="group" aria-labelledby="product-radio-group">
            <FieldWrapper>
              <Field type="radio" name={productId} value="0" as={RadioV2} />

              <TertiaryText>
                <FormattedMessage {...cartMessages.productResignation} />
              </TertiaryText>
            </FieldWrapper>
            <FieldWrapper style={{ marginLeft: theme.spacing.base }}>
              <Field
                type="radio"
                name={productId}
                value={String(quantityAdded)}
                as={RadioV2}
              />

              <TertiaryText>
                <FormattedMessage {...cartMessages.agreeToOrderLess} />
              </TertiaryText>
            </FieldWrapper>
          </RadioGroupWrapper>
        ) : (
          <FieldWrapper>
            <Field name={productId} type="checkbox" as={CheckboxV2} />

            <TertiaryText>
              <FormattedMessage {...cartMessages.lackOfProductAgreement} />
            </TertiaryText>
          </FieldWrapper>
        ))}
    </>
  )
}

export default productContainer(ProductRowBoxMissing)
