import React, { useState } from 'react'

import theme from 'theme'
import { ArrowLongLeft, ArrowLongRight } from 'components/Icons'

import {
  PaginationWrapper,
  PaginationBtn,
  PaginationInput,
  PaginaitonPlaceholder,
} from './styles'

const Pagination = ({
  className,
  current,
  totalPages,
  onChange,
  prevIcon,
  nextIcon,
  prevBtnTestId = 'prev-btn',
  nextBtnTestId = 'next-btn',
}) => {
  const [isInput, setIsInput] = useState(false)

  if (Number.isInteger(current) && Number.isInteger(totalPages) && !!onChange) {
    const isFirstPage = current <= 1
    const isLastPage = current >= totalPages

    const defaultPrevIcon = (
      <ArrowLongLeft color={theme.colors[isFirstPage ? 'gray6' : 'gray5']} />
    )
    const defaultNextIcon = (
      <ArrowLongRight color={theme.colors[isLastPage ? 'gray6' : 'gray5']} />
    )

    const handleInput = value => {
      if (!!value && Math.trunc(Number(value)) !== current) {
        onChange(Number(value))
      }

      return setIsInput(false)
    }

    const handleChange = e => {
      if (e.target.value > totalPages) {
        e.target.value = totalPages
      } else if (e.target.value !== '' && e.target.value < 1) {
        e.target.value = 1
      }
    }

    const handleKeyDown = e => {
      if (e.key === 'Enter') {
        handleInput(e.target.value)
      }
    }

    const handleBlur = e => {
      handleInput(e.target.value)
    }

    return (
      <PaginationWrapper {...{ className }} data-test-id="pagination-wrapper">
        <PaginationBtn
          isDisabled={isFirstPage}
          onClick={() => !isFirstPage && onChange(current - 1)}
          data-test-id={prevBtnTestId}
        >
          {prevIcon || defaultPrevIcon}
        </PaginationBtn>
        {isInput ? (
          <PaginationInput
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onChange={handleChange}
            type="number"
            data-test-id="pagination-input"
            autoFocus
          />
        ) : (
          <PaginaitonPlaceholder
            title={`${current}/${totalPages}`}
            onClick={() => setIsInput(true)}
          >
            <span>{current}</span>
            <span>z</span>
            <span>{totalPages}</span>
          </PaginaitonPlaceholder>
        )}
        <PaginationBtn
          isDisabled={isLastPage}
          onClick={() => !isLastPage && onChange(current + 1)}
          data-test-id={nextBtnTestId}
        >
          {nextIcon || defaultNextIcon}
        </PaginationBtn>
      </PaginationWrapper>
    )
  }

  return null
}

export default Pagination
