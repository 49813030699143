import styled from 'styled-components'

import { BreadCrumbList } from 'components/BreadCrumbs'
import { BreadCrumbsContainer } from 'views/Products/Header/ProductsBreadcrumbs/styles'
import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'

export const ProductImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  ${BreadCrumbsContainer} {
    margin: 0;
  }
  ${BreadCrumbList} {
    margin-top: 0;
  }
`

export const ProductImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  > img {
    width: 100%;
    aspect-ratio: 1;
  }
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    > img {
      max-width: 456px;
    }
  }
`

export const SliderControlsWrapper = styled.div`
  gap: ${theme.spacing.ss};
  display: flex;
  width: 100%;
  justify-content: center;
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin-top: ${theme.spacing.sm};
    gap: 16px;
    position: relative;
    justify-content: start;
  }
  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    margin-top: 0;
    flex-direction: column;
    position: absolute;
    width: auto;
  }
`

export const SliderControlsThumbnailContainer = styled.div`
  height: 77px;
  width: 77px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid
    ${p =>
      p.$active ? theme.colors.secondary : theme.colors.semiTranspBg.gray};
  ${p => !p.$active && 'opacity: 0.5'};
  :hover {
    opacity: 1;
  }
`

export const SliderControlsDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${p =>
    p.$active ? theme.colors.primary : theme.colors.lightGreyHover};
`
