import React from 'react'

import { ArrowLeftV2 } from 'components/Icons'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import CarouselTracker from 'components/Carousel/CarouselTracker'
import TwButton from 'components/Button/TwButton'

const CarouselControls = ({
  snaps,
  selectedSnapIndex,
  handleNextClick,
  handlePrevClick,
}) => {
  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)

  if (isTablet && snaps.length > 1) {
    return (
      <div className="absolute bottom-6 right-6 flex gap-2">
        <TwButton
          type="button"
          className="bg-white hover:bg-grey-400 p-2"
          onClick={handlePrevClick}
          data-test-id="prev-campaign-button"
        >
          <ArrowLeftV2 />
        </TwButton>
        <TwButton
          type="button"
          className="bg-white hover:bg-grey-400 p-2 rotate-180"
          onClick={handleNextClick}
          data-test-id="next-campaign-button"
        >
          <ArrowLeftV2 />
        </TwButton>
      </div>
    )
  }

  return <CarouselTracker snaps={snaps} selectedSnapIndex={selectedSnapIndex} />
}

export default CarouselControls
