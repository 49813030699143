import React, { useEffect, useMemo } from 'react'
import { generatePath, useParams, useNavigate } from 'react-router'
import { useIntl } from 'react-intl'

import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS, ROUTES } from 'consts'
import { getAuthToken } from 'containers/Auth/tokenStorage'
import Breadcrumbs from 'components/Layout/Breadcrumbs/MainPage'
import LoaderFullHeight from 'components/LoaderFullHeight'
import CategoriesList from 'components/KnowledgeBase/CategoriesList'
import {
  useKnowledgeBaseCategories,
  getPublicCategories,
} from 'components/KnowledgeBase/utils'

import EntryContent from './EntryContent'
import messages from './messages'

const KnowledgeBase = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const params = useParams()
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  const faqPagePath = generatePath(ROUTES.KNOWLEDGE_BASE, {
    category: 'faq',
  })

  const menuPagePath = generatePath(ROUTES.KNOWLEDGE_BASE)

  const {
    categories,
    featuredCategories,
    featuredArticles,
    featuredQuestions,
    fetchFilteredArticles,
    filteredCategories,
    isKnowledgeBaseLoading,
    isListLoading,
    isListFiltered,
    isInitialized,
  } = useKnowledgeBaseCategories()

  const token = getAuthToken()
  useEffect(
    () => {
      // render main page for logged user
      if (token) {
        if (!params.category && isDesktop) {
          navigate(faqPagePath)
        }
        return
      }

      // redirect unlogged user to first category in list
      const publicCategories = getPublicCategories(categories)
      if (publicCategories.length && !params.category) {
        navigate(
          generatePath(ROUTES.KNOWLEDGE_BASE, {
            category: publicCategories[0].categoryId,
          }),
          { replace: true },
        )
      }
    },
    [token, categories, params.category, isDesktop, faqPagePath],
  )

  const { selectedCategory, selectedArticle } = useMemo(
    () => {
      if (!categories.length) return {}

      if (params.category === 'faq') {
        return { selectedCategory: 'faq' }
      }

      const category = categories.find(
        ({ categoryId }) => categoryId === params.category,
      )

      return {
        selectedCategory: category,
        selectedArticle: category?.articles.find(
          ({ articleId }) => articleId === params.article,
        ),
      }
    },
    [params, categories, isDesktop, faqPagePath],
  )

  const breadcrumbs = useMemo(
    () => {
      let newBreadcrumbs = []

      if (selectedCategory && !isDesktop) {
        newBreadcrumbs = [
          ...newBreadcrumbs,
          {
            title: formatMessage(messages.menuTitle),
            path: menuPagePath,
          },
        ]
      }

      if (selectedCategory && selectedCategory !== 'faq' && token) {
        newBreadcrumbs = [
          ...newBreadcrumbs,
          {
            title: formatMessage(messages.mainTitle),
            path: faqPagePath,
          },
        ]
      }

      if (selectedArticle) {
        newBreadcrumbs = [
          ...newBreadcrumbs,
          {
            title: selectedCategory.title,
            path: generatePath(ROUTES.KNOWLEDGE_BASE, {
              category: selectedCategory.categoryId,
            }),
          },
        ]
      }

      return newBreadcrumbs
    },
    [selectedCategory, selectedArticle, token, isDesktop],
  )

  const entryTitle = selectedArticle?.title || selectedCategory?.title

  if (isKnowledgeBaseLoading) return <LoaderFullHeight />

  return (
    <div className="flex flex-col w-full gap-6 pb-14 lg:gap-8">
      <div className="flex flex-col pt-4 pb-4 px-6 border-b border-solid border-grey-300 lg:px-8">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1
          data-test-id="kb-title"
          className="text-[22px] leading-[30px] font-semibold pt-8 lg:text-h1 lg:pt-14"
        >
          {entryTitle || formatMessage(messages.mainTitle)}
        </h1>
      </div>
      <div className="flex gap-12 px-6 lg:px-8">
        {(isDesktop || !selectedCategory) && (
          <div className="grow lg:block lg:w-[284px] lg:shrink-0 lg:grow-0">
            <div className="sticky top-[calc(var(--inset-nav-height)+32px)] lg:top-[calc(var(--inset-lg-nav-height)+32px)]">
              <CategoriesList
                categories={filteredCategories}
                fetchEntries={fetchFilteredArticles}
                isLoading={isListLoading}
                isFiltered={isListFiltered}
                isInitialized={isInitialized}
                faqPagePath={faqPagePath}
              />
            </div>
          </div>
        )}
        <EntryContent
          selectedCategory={selectedCategory}
          selectedArticle={selectedArticle}
          featuredCategories={featuredCategories}
          featuredArticles={featuredArticles}
          featuredQuestions={featuredQuestions}
        />
      </div>
    </div>
  )
}

export default KnowledgeBase
