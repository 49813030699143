import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { StyledScroll } from 'components/Scroll/styles'

export const FiltersWrapper = styled(StyledScroll)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  .simplebar-content-wrapper {
    align-content: center;
  }

  // workaround resizing issue
  .simplebar-placeholder {
    width: auto !important;
  }

  .simplebar-content {
    display: flex;
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    top: auto;
    bottom: 0;
    height: 4px;
  }

  .simplebar-scrollbar::before {
    width: auto;
  }
`

export const FiltersControl = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
`

export const Title = styled.h5`
  font-family: ${theme.fonts.primary};
  font-size: 11px;
  font-weight: 500;
  color: ${theme.colors.primary};
  margin-right: auto;
`

export const ButtonFiltersWrapper = styled(Flex)`
  ${props =>
    props.hasSpacingLeft &&
    `
    border-left: 1px solid ${theme.colors.lightGrey2};
    margin-left: ${theme.spacing.xs};
    padding-left: ${theme.spacing.xs};
  `} > span:focus {
    outline: none;
  }
  > span:not(:first-child):not(:last-child) {
    margin: 0 2px;
  }
`

export const StyledButtonFilter = styled.button`
  white-space: nowrap;
  font-family: ${theme.fonts.primary};
  user-select: none;
  cursor: pointer;
  color: ${theme.colors.primary};
  font-size: 11px;
  border-radius: 8px;
  padding: 5px 8px;
  :hover {
    background-color: ${theme.colors.lightGrey};
  }
  :not(:nth-of-type(1)) {
    margin-left: 8px;
  }
  ${props =>
    props.isActive &&
    `
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
    :hover {
      background-color: ${theme.colors.greenLight};
    }
  `};
  ${props =>
    props.isDisabled &&
    `
    cursor: default;
    pointer-events: none;
    opacity: 0.3;
    :hover {
      background: none;
    }
  `};
`
