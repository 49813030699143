import styled from 'styled-components'

import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'

import theme from 'theme'

// prettier-ignore
export const StyledScroll = styled(SimpleBar)`
  .simplebar-scrollbar::before {
    background-color: ${theme.colors.gray5};
    border-radius: 2px;
    width: 4px;
  }
  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }
`

export const DropdownScroll = styled(StyledScroll)`
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 8px 16px;
  .simplebar-vertical {
    top: 12px;
    bottom: 12px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`
