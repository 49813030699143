import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { identity, get } from 'lodash'
import { generatePath } from 'react-router'

import { ROUTES } from 'consts'
import {
  isOutOfStock,
  isElasticStock,
  getProductTemplateInclusions,
} from 'utils'
import { isTooltipOpenSelector } from 'containers/App/selectors'
import { ALERT_TYPE } from 'components/Alert'
import CatalogVariantsSelect from 'components/VariantsSelect/CatalogVariantsSelect'
import { Link } from 'components/Text'
import { isSalePromoType } from 'containers/Promotions/utils'
import { isProductWithBadgeFeatures } from 'components/Product/Badge/utils'
import { useSelectItemTracking } from 'services/analytics'
import Availability from '../Availability'

import {
  findUnitOfMeasureByVariant,
  getNotificationMsg,
  formatUnitAmount,
  getBidmarketLabel,
} from '../utils'

import CatalogButtonsContainer from '../ButtonsContainer/CatalogButtonsContainer'
import CatalogProductPrices from '../ProductPrices/CatalogProductPrices'
import ProductImage from '../ProductImage'
import PromoGroup from '../PromoGroup'
import PromotionBadge from '../PromotionBadge'
import SaleProgressBar from '../SaleProgressBar'
import ProductTitle from '../ProductTitle'
import SystemTemplateIcon from '../SystemTemplateIcon'
import BookmarkIcon from '../BookmarkIcon'

import {
  ProductImgContainer,
  ProductBoxContainer,
  ProductInfo,
  PromoBadgeContainer,
  ProductBoxAvailabilityWrapper,
  StockConfirmContainer,
  TitleContainer,
  ProductNotification,
  ProductTileActions,
  VariantsActions,
  VariantsWrapper,
  Spacer,
  ProductLink,
  IconsBar,
  IconsBarSeparator,
  ProductBoxPromoText,
  SaleBarContainer,
} from './styles'
import messages from './messages'
import BidmarketLabel from '../BidmarketLabel'

const ProductBox = props => {
  const {
    onProductDetailsClick = identity,
    isPromoGroupModal = true,
    baseUnitOfMeasure,
    baseUnitOfMeasureDescription: baseUnitDesc,
    brand,
    hideBookmark,
    id,
    inTemplate,
    name,
    pricePerUnit,
    nonStock,
    isCatalog,
    unitsOfMeasure,
    refetchDataCallback,
    className,
    replacements,
    renderButtons,
    unitForPricePerUnit,
    active,
    product,
    hidePromo,
    onAddToCart,
    onRemoveFromCart,
    refetchOnCartClick,
    suppressGetDeliveryDates,
    floatingPrice,
    itemListId,
    itemListName,
    itemModelId,
    attributionToken,
    additionalEventParams,
    index,
  } = props
  const { formatMessage } = useIntl()
  const [variant, setVariant] = useState(null)
  const [notificationVisible, setNotificationVisible] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState(null)
  const [notificationType, setNotificationType] = useState(ALERT_TYPE.SUCCESS)

  const isTooltipOpen = useSelector(isTooltipOpenSelector(id))
  const confirmStockRef = useRef(null)

  let timeout = null
  useEffect(() => clearTimeout(timeout))

  const { promotion, features } = product
  const productPath = generatePath(ROUTES.PRODUCT_DETAILS, {
    id,
  })
  const unitData = findUnitOfMeasureByVariant(unitsOfMeasure, variant)
  const { stock, isStockLimited, unitOfMeasure } = unitData
  const outOfStock = isOutOfStock({ stock, nonStock })
  const isInTemplate = get(unitData, 'inTemplate', inTemplate)
  // Backwards compatable user template check
  const [isInUserTemplate, isInSystemTemplate] = getProductTemplateInclusions(
    unitData,
  )

  const bidmarketLabel = getBidmarketLabel(features)
  const hasOfferFeatures = isProductWithBadgeFeatures(product)
  const showPromoText = !hidePromo && promotion?.title
  const showPromoBadge = !hidePromo && (promotion || hasOfferFeatures)
  const isVariants = unitsOfMeasure.length > 1
  const ActionsWrapper = isVariants ? VariantsActions : ProductTileActions
  const isIconsBarSeparator =
    !hideBookmark && (isInSystemTemplate || !!bidmarketLabel)

  const toggleModalDisplay = ({
    notificationMessageProp,
    notificationTypeProp,
  } = {}) => {
    setNotificationVisible(prevState => !prevState)
    notificationMessageProp && setNotificationMessage(notificationMessageProp)
    notificationTypeProp && setNotificationType(notificationTypeProp)
  }

  const showNotification = ({
    notificationMessageProp,
    notificationTypeProp,
  }) => {
    toggleModalDisplay({ notificationMessageProp, notificationTypeProp })

    timeout = setTimeout(() => toggleModalDisplay(), 2000)
  }

  const addRemoveCallbackWithMsg = params => {
    const { justAddedToCart, removedFromCart } = params

    showNotification({
      notificationMessageProp: formatMessage(
        messages[
          getNotificationMsg({
            justAddedToCart,
            removedFromCart,
          })
        ],
      ),
      notificationTypeProp: ALERT_TYPE.SUCCESS,
    })

    if (onRemoveFromCart && removedFromCart) {
      onRemoveFromCart(product)
    } else if (onAddToCart && justAddedToCart) {
      onAddToCart()
    }
  }

  const trackSelectItem = useSelectItemTracking({
    product,
    itemListId,
    itemListName,
    itemModelId,
    unitOfMeasure: variant?.value,
    additionalEventParams,
    index,
  })
  const handleProductDetailsClick = () => {
    trackSelectItem()
    onProductDetailsClick?.({ unitOfMeasure: variant?.value, ...props })
  }

  const handleNotifyStockUnavailable = (stockAmount, unitDataArg) => {
    if (!isStockLimited) {
      showNotification({
        notificationTypeProp: ALERT_TYPE.INFO,
        notificationMessageProp: formatMessage(messages.maxAvailableAmount, {
          amount: formatUnitAmount(unitDataArg, stockAmount),
        }),
      })
    }
  }

  const ParsedPrices = (
    <CatalogProductPrices
      promotion={promotion}
      baseUnitDesc={baseUnitDesc}
      baseUnitOfMeasure={baseUnitOfMeasure}
      pricePerUnit={pricePerUnit}
      unitForPricePerUnit={unitForPricePerUnit}
      unitsOfMeasure={unitsOfMeasure}
      hasFloatingPrice={floatingPrice}
    />
  )

  return (
    <ProductLink
      onClick={handleProductDetailsClick}
      $isCatalog={isCatalog}
      data-test-id={`product#${id}`}
      to={productPath}
      state={{ itemListId, itemListName, itemModelId, attributionToken, index }}
    >
      <ProductBoxContainer
        replacements={replacements}
        data-test-id={id.replace(':', '_')}
        {...{ className, isVariants, isTooltipOpen }}
      >
        <ProductNotification
          visible={notificationVisible}
          type={notificationType}
        >
          {notificationMessage}
        </ProductNotification>
        <IconsBar>
          {!hideBookmark && (
            <BookmarkIcon
              {...{
                product,
                unitOfMeasure,
                isInUserTemplate,
                itemListName,
                itemListId,
                itemModelId,
              }}
              listIndex={index}
            />
          )}
          {isIconsBarSeparator && <IconsBarSeparator />}
          {isInSystemTemplate && <SystemTemplateIcon productId={id} />}
          {!!bidmarketLabel && (
            <BidmarketLabel separatorSize={0} label={bidmarketLabel} />
          )}
        </IconsBar>
        <ProductImgContainer>
          <ProductImage {...{ outOfStock, product }} />
        </ProductImgContainer>
        {showPromoBadge && (
          <PromoBadgeContainer>
            <PromotionBadge product={product} productFeaturesLimit={1} />
          </PromoBadgeContainer>
        )}
        <ProductInfo>
          {isElasticStock({ stock, nonStock }) && (
            <StockConfirmContainer ref={confirmStockRef} />
          )}
          <TitleContainer>
            <ProductTitle title={name} {...{ brand }} />
          </TitleContainer>
          <Availability
            product={product}
            unitOfMeasureObj={unitData}
            WrapComponent={ProductBoxAvailabilityWrapper}
          />
          {showPromoText &&
            (isPromoGroupModal ? (
              <PromoGroup
                groupId={promotion.groupId}
                showModal={promotion.showModal}
              >
                <ProductBoxPromoText $isShowModal={!!promotion.showModal}>
                  {promotion.title}
                </ProductBoxPromoText>
              </PromoGroup>
            ) : (
              <ProductBoxPromoText>{promotion.title}</ProductBoxPromoText>
            ))}
          <Spacer />
          <ActionsWrapper
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            {isVariants ? (
              <VariantsWrapper {...{ isTooltipOpen }}>
                <CatalogVariantsSelect
                  centerPointer
                  {...{ unitsOfMeasure, ParsedPrices }}
                  defaultFillPredicate={() => true}
                  handleChange={option => setVariant(option)}
                  value={variant}
                  productId={id}
                  isDisabled={!active || isTooltipOpen}
                />
              </VariantsWrapper>
            ) : (
              <Link
                to={productPath}
                state={{ itemListId, itemListName, itemModelId, index }}
                onClick={handleProductDetailsClick}
              >
                {isSalePromoType(promotion) && (
                  <SaleBarContainer>
                    <SaleProgressBar {...{ promotion, baseUnitDesc }} />
                  </SaleBarContainer>
                )}
                {ParsedPrices}
              </Link>
            )}
            {renderButtons ? (
              renderButtons({
                unitOfMeasure: unitData,
                showNotification,
              })
            ) : (
              <CatalogButtonsContainer
                {...{
                  nonStock,
                  stock,
                  refetchDataCallback,
                  unitData,
                  product,
                  outOfStock,
                  isVariants,
                  refetchOnCartClick,
                  suppressGetDeliveryDates,
                  index,
                }}
                confirmStockRef={confirmStockRef.current}
                onNotifyStockUnavailable={handleNotifyStockUnavailable}
                addRemoveCallback={addRemoveCallbackWithMsg}
                inTemplate={isInTemplate}
                productId={id}
                itemListName={itemListName}
                itemListId={itemListId}
                itemModelId={itemModelId}
                additionalEventParams={additionalEventParams}
                attributionToken={attributionToken}
              />
            )}
          </ActionsWrapper>
        </ProductInfo>
      </ProductBoxContainer>
    </ProductLink>
  )
}

ProductBox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  brand: PropTypes.string,
  onProductDetailsClick: PropTypes.func,
}

export default ProductBox
