import { makeMessages, makeTranslation } from 'utils'

const messages = {
  read: 'read',
  topicsCount: 'topicsCount',
  mainTitle: 'mainTitle',
  more: { id: 'more' },
  featuredCategories: 'featuredCategories',
  featuredArticles: 'featuredArticles',
  featuredQuestions: 'featuredQuestions',
  menuTitle: 'menuTitle',
}

export default makeMessages(messages, makeTranslation('knowledgeBase'))
