import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import { APP_BREAKPOINTS } from 'consts'
import Breadcrumbs from 'views/Products/Header'
import ImageSliderControls from 'views/ProductDetails/ProductImageSection/ImageSliderControls'
import ProductImage, {
  PRODUCT_IMAGE_SIZES,
} from 'components/Product/ProductImage'
import { useSnapDetails } from 'components/Carousel/utils'
import { useOfMinWidth } from 'hooks'

import {
  ProductImgContainer,
  ProductImageWrapper,
} from 'views/ProductDetails/ProductImageSection/styles'
import { PDP_EVENTS, trackSimpleEvent } from 'services/analytics'
import CarouselBorderFade from 'components/Carousel/CarouselBorderFade'
import { getBreadcrumbsParams } from '../utils'

const DESKTOP_BREAKPOINT = `(min-width: ${APP_BREAKPOINTS.DESKTOP_START}px)`

const ProductImageSection = ({ product, isProductDisabled }) => {
  const productParams = getBreadcrumbsParams(product)

  const handleSlideChange = index => {
    trackSimpleEvent(PDP_EVENTS.PHOTO_CLICKED, {
      position: index + 1,
      photo_count: product.images.length > 3 ? 3 : product.images.length,
    })
  }

  const [emblaRef, emblaApi] = useEmblaCarousel({
    breakpoints: {
      [DESKTOP_BREAKPOINT]: {
        watchDrag: false,
        axis: 'y',
      },
    },
  })
  const {
    selectedIndex,
    scrollSnaps,
    onClick: onThumbnailClick,
  } = useSnapDetails(emblaApi, { slideChangeCallback: handleSlideChange })

  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  return (
    <ProductImgContainer>
      <Breadcrumbs productParams={productParams} />
      <div className="w-full flex flex-col">
        <div className="overflow-hidden -mx-4 md:-mx-8 lg:m-0 relative">
          <CarouselBorderFade vertical={isDesktop} />
          <div className="w-full" ref={emblaRef}>
            <div className="flex *:flex-[0_0_100%] lg:flex-col lg:h-[456px]">
              {product.images.length ? (
                product.images.slice(0, 3).map(img => (
                  <ProductImageWrapper key={`carousel-${img.urlBig}`}>
                    <ProductImage
                      outOfStock={isProductDisabled}
                      product={product}
                      imageSrc={img.urlBig}
                      size={PRODUCT_IMAGE_SIZES.L}
                      suppressImgGraying={!product.active}
                    />
                  </ProductImageWrapper>
                ))
              ) : (
                // display placeholder as fallback
                <ProductImageWrapper>
                  <ProductImage
                    outOfStock={isProductDisabled}
                    product={product}
                    size={PRODUCT_IMAGE_SIZES.L}
                    suppressImgGraying={!product.active}
                  />
                </ProductImageWrapper>
              )}
            </div>
          </div>
        </div>
        {product.images.length && (
          <ImageSliderControls
            isProductDisabled={isProductDisabled}
            handleSelectImage={onThumbnailClick}
            product={product}
            snaps={scrollSnaps}
            selectedIndex={selectedIndex}
          />
        )}
      </div>
    </ProductImgContainer>
  )
}

export default ProductImageSection
