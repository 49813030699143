import React from 'react'

import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import SkeletonBlock from 'components/Skeletons/SkeletonBlock'

const CampaignDetailsLayout = props => {
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  const { image, title, description, buttons, products, logo } = props.isLoading
    ? {
        title: (
          <div className="flex flex-col gap-2">
            <SkeletonBlock className="w-full h-8 lg:h-12" />
            <SkeletonBlock className="w-[300px] h-8 lg:w-[340px] lg:h-12" />
          </div>
        ),
        image: (
          <SkeletonBlock className="w-full h-[224px] rounded-3xl md:h-[300px]" />
        ),
      }
    : props

  if (!isDesktop) {
    return (
      <div className="flex flex-col gap-10 md:gap-4">
        <div className="flex flex-col gap-8 py-6 px-4">
          <div className="flex flex-col gap-6">
            {image}
            {title && <div className="md:mr-[52px]">{title}</div>}
          </div>
          {(description || buttons || logo) && (
            <div className="flex flex-col gap-4 md:mr-[52px]">
              {logo}
              {(description || buttons) && (
                <div className="flex flex-col gap-6">
                  {description}
                  {buttons}
                </div>
              )}
            </div>
          )}
        </div>
        {products}
      </div>
    )
  }

  return (
    <div className="flex px-8 gap-6 items-start">
      <div className="flex flex-col gap-6 w-[411px] sticky top-[73px]">
        <div className="flex flex-col gap-6 pr-8 min-h-[300px]">
          <div className="py-4">{title}</div>
          {logo && <div className="mt-auto">{logo}</div>}
          {(!products || !description) && buttons}
        </div>
        {!!products && (
          <div className="flex flex-col gap-6 mr-8 mt-5">
            {description}
            {description && buttons}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-6 flex-1">
        {image}
        {products || description}
      </div>
    </div>
  )
}

export default CampaignDetailsLayout
