import { makeMessages, makeTranslation } from 'utils'

const messages = {
  mainTitle: 'mainTitle',
  searchPlaceholder: 'searchPlaceholder',
  clear: { id: 'common.labels.clear' },
  noResults: 'noResults',
}

export default makeMessages(messages, makeTranslation('knowledgeBase'))
