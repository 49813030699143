import * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import usePrevious from 'use-previous'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  arrow,
  useHover,
  safePolygon,
  useTransitionStyles,
  FloatingOverlay,
} from '@floating-ui/react'

import theme from 'theme'
import {
  Arrow,
  CloseElementContainer,
  ContentPositioningContainer,
  TriggerContainer,
} from './styles'

export const INTERACTION_KINDS = {
  CLICK: 'click',
  HOVER: 'hover',
}

export const DEFAULT_OFFSET_VALUES = {
  main: 3,
  cross: 0,
}

const ARROW_HEIGHT = 7

export const usePopover = ({
  disabled,
  // {main, cross}
  offsetValues,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  disableArrow = false,
  // fixes placement to provided one or 'top'
  fixedPlacement = false,
  interactionKind = INTERACTION_KINDS.CLICK,
  closeDelay,
  // allows for hovering on floating element to keep popover open
  keepOpenOnFloatingHover,
  // disables dismiss (onClickOutside in our case) when needed
  disableDismiss,
  onClickOutside,
  onOpen,
  onClose,
  onClosed,
} = {}) => {
  const usedOffsetValues = {
    ...DEFAULT_OFFSET_VALUES,
    ...offsetValues,
  }

  const [uncontrolledOpen, setUncontrolledOpen] = useState(false)
  const arrowRef = useRef(null)

  const isControlled = controlledOpen !== undefined

  const open = disabled ? false : controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const prevOpen = usePrevious(open)
  useEffect(
    () => {
      if (prevOpen !== undefined) {
        if (!open && prevOpen) {
          onClose?.()
        }

        if (open && !prevOpen) {
          onOpen?.()
        }
      }
    },
    [open, prevOpen],
  )

  const data = useFloating({
    placement,
    open,
    onOpenChange: (newState, event, reason) => {
      setOpen(newState)

      if (reason === 'outside-press') {
        onClickOutside?.(newState)
      }
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: disableArrow
          ? usedOffsetValues.main
          : ARROW_HEIGHT + usedOffsetValues.main,
        crossAxis: usedOffsetValues.cross,
      }),
      flip({
        mainAxis: !fixedPlacement,
      }),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  })

  const { context } = data

  const hover = useHover(context, {
    enabled: !isControlled && interactionKind === INTERACTION_KINDS.HOVER,
    delay: {
      close: closeDelay,
    },
    handleClose: keepOpenOnFloatingHover ? safePolygon() : null,
  })

  const click = useClick(context, {
    enabled: !isControlled && interactionKind === INTERACTION_KINDS.CLICK,
  })

  const dismiss = useDismiss(context, {
    enabled: !disableDismiss && interactionKind === INTERACTION_KINDS.CLICK,
    outsidePressEvent: 'mousedown',
    escapeKey: false,
  })

  const interactions = useInteractions([click, dismiss, hover])

  return useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      disableArrow,
      onClosed,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data, arrowRef, disableArrow],
  )
}

const PopoverContext = React.createContext(null)

export const usePopoverContext = (ignoreCheck = false) => {
  const context = React.useContext(PopoverContext)

  if (context == null && ignoreCheck) {
    return {}
  }
  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />')
  }

  return context
}

export function Popover({ children, ...restOptions }) {
  const popover = usePopover(restOptions)
  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  )
}

export const PopoverTrigger = ({
  children,
  ref,
  asChild = false,
  ...props
}) => {
  const context = usePopoverContext()
  const childrenRef = children.ref
  const mergedRef = useMergeRefs([context.refs.setReference, ref, childrenRef])

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref: mergedRef,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      }),
    )
  }

  return (
    <TriggerContainer
      ref={mergedRef}
      // style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </TriggerContainer>
  )
}

export const PopoverContent = ({
  ref,
  style,
  withOverlay = false,
  // useTransitionStyle values for overlay and content, by default:
  // opacity 0 => 1
  // duration = 250
  transitions,
  transitionContainerStyle = {},
  matchTriggerWidth,
  asBottomModal,
  portalNodeId,
  ...props
}) => {
  const { context: floatingContext, onClosed, ...context } = usePopoverContext()
  const mergedRef = useMergeRefs([context.refs.setFloating, ref])

  const { styles: overlayTransition } = useTransitionStyles(
    floatingContext,
    transitions?.overlay,
  )

  const { isMounted, styles: contentTransition } = useTransitionStyles(
    floatingContext,
    transitions?.content,
  )

  const prevIsMounted = usePrevious(isMounted)

  useEffect(
    () => {
      if (!isMounted && prevIsMounted) {
        onClosed?.()
      }
    },
    [isMounted],
  )

  const positioningContainerStyle = useMemo(
    () => {
      if (asBottomModal) {
        return {
          zIndex: theme.zIndex.modalOverlay + 1,
          position: 'fixed',
          bottom: 0,
          width: '100%',
        }
      }

      if (matchTriggerWidth) {
        return {
          width: context.refs.reference.current?.scrollWidth,
          ...context.floatingStyles,
          ...style,
        }
      }

      return { ...context.floatingStyles, ...style }
    },
    [context, asBottomModal, style],
  )

  if (!isMounted) return null

  return (
    <FloatingPortal id={portalNodeId}>
      {withOverlay && (
        <FloatingOverlay
          data-test-id="modal-overlay"
          lockScroll
          style={overlayTransition}
        />
      )}
      <ContentPositioningContainer
        data-test-id="popover-content"
        ref={mergedRef}
        style={positioningContainerStyle}
        {...context.getFloatingProps(props)}
      >
        <div style={{ ...contentTransition, ...transitionContainerStyle }}>
          {!context.disableArrow && (
            <Arrow ref={context.arrowRef} context={context} />
          )}
          {props.children}
        </div>
      </ContentPositioningContainer>
    </FloatingPortal>
  )
}

export const PopoverClose = ({ ref, ...props }) => {
  const { setOpen } = usePopoverContext(true)
  return (
    <CloseElementContainer
      ref={ref}
      {...props}
      onClick={event => {
        props.onClick?.(event)
        setOpen?.(false)
      }}
    />
  )
}
