import React, { useRef, useImperativeHandle } from 'react'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { SearchV2 as SearchIcon, X as ClearBtnIcon } from 'components/Icons'

import {
  SearchInput,
  SearchBtn,
  SearchContainer,
  ClearBtnContainer,
} from './styles'

const Search = ({
  ref,
  isActive,
  containerTestId,
  onSearchBtnClick,
  searchBtnTestId,
  inputTestId,
  value,
  onKeyDown,
  onInputChange,
  onInputBlur,
  clearBtnTestId,
  onClearBtnClick,
  desktopBreakpoint = APP_BREAKPOINTS.DESKTOP_START,
  className,
  btnClassName,
  inputClassName,
  clearClassName,
  placeholder,
}) => {
  const containerRef = useRef(null)
  const inputRef = useRef(null)

  useImperativeHandle(ref, () => ({
    containerRef: containerRef.current,
    inputRef: inputRef.current,
  }))

  return (
    <SearchContainer
      className={className}
      ref={containerRef}
      isActive={isActive}
      data-test-id={containerTestId}
      $desktopBreakpoint={desktopBreakpoint}
    >
      <SearchBtn
        className={btnClassName}
        onClick={onSearchBtnClick}
        data-test-id={searchBtnTestId}
      >
        <SearchIcon />
      </SearchBtn>

      <SearchInput
        data-test-id={inputTestId}
        className={inputClassName}
        disabled={!isActive}
        ref={inputRef}
        value={value}
        onKeyDown={e => onKeyDown(e)}
        onChange={e => onInputChange(e.target.value)}
        onBlur={e => onInputBlur(e)}
        placeholder={placeholder}
        $desktopBreakpoint={desktopBreakpoint}
      />

      {value && (
        <ClearBtnContainer
          className={clearClassName}
          data-test-id={clearBtnTestId}
          onClick={onClearBtnClick}
        >
          <ClearBtnIcon color={theme.colors.gray5} />
        </ClearBtnContainer>
      )}
    </SearchContainer>
  )
}

export default Search
