import React from 'react'
import { cn } from 'utils'

const ListItemSkeleton = ({ className }) => (
  <div className={cn('flex items-center gap-4 py-2 px-4', className)}>
    <div className="size-6 bg-linear-to-l from-grey-100 to-grey-400 rounded-sm" />
    <div className="bg-linear-to-l from-grey-100 to-grey-400 h-6 flex-1 rounded-sm" />
  </div>
)

export default ListItemSkeleton
