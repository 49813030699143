import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { ProductInfoContainer } from 'views/ProductDetails/ProductInfoSection/styles'
import {
  ProductControlsContainer,
  StickyContainer,
} from 'views/ProductDetails/CartControlsSection/styles'
import {
  DESKTOP_HEIGHT as DESKTOP_HEADER_HEIGHT,
  MOBILE_HEIGHT as MOBILE_HEADER_HEIGHT,
} from 'containers/App/LoggedInLayout/Header/styles'

const PADDING_TABLET = 32

export const ProductDetailsTopSection = styled.div`
  display: grid;
  gap: ${theme.spacing.md};
  width: 100%;

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    grid-template-columns: 1fr 1fr;

    ${ProductInfoContainer} {
      grid-column: span 2;
    }

    ${StickyContainer} {
      top: ${MOBILE_HEADER_HEIGHT + PADDING_TABLET}px;
    }
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    grid-template-columns: 2fr 1fr;

    ${ProductInfoContainer} {
      grid-column: auto;
    }

    ${ProductControlsContainer} {
      grid-row: span 2;
    }

    ${StickyContainer} {
      top: ${DESKTOP_HEADER_HEIGHT + PADDING_TABLET}px;
    }
  }
`
