import React from 'react'

const Skeleton = () => (
  <div className="flex flex-col gap-2 w-full">
    <div className="pt-4 pb-2">
      <div className="h-6 w-[124px] bg-linear-to-l from-grey-100 to-grey-400 rounded-sm" />
    </div>
    <div className="h-28 bg-linear-to-l from-grey-100 to-grey-400 rounded-lg" />
  </div>
)

export default Skeleton
