import React from 'react'
import { useIntl } from 'react-intl'
import qs from 'qs'
import { useSelector } from 'react-redux'
import { useNavigate, generatePath } from 'react-router'
import useEmblaCarousel from 'embla-carousel-react'

import { ROUTES } from 'consts'
import {
  DefaultCategoriesIcon,
  DefaultCuisinesIcon,
  ICONS_MAP,
} from 'components/CategoriesDropdown/Icons'
import { ArrowRightXS } from 'components/Icons'
import { FILTER_EVENTS, NAV_BAR_EVENTS } from 'services/analytics'

import { userDataSelector } from 'containers/UserInfo/selectors'
import FilteredPageTile from 'components/FilteredPageTile'
import { DROPDOWN_FILTERED_PAGE_VARIANTS } from 'components/CategoriesDropdown/FilteredPagesSection/consts'
import {
  useCategoriesListSelect,
  trackCategoriesDropdownEvent,
} from 'components/CategoriesDropdown/utils'
import { FILTERED_PAGE_MAP } from 'components/FilteredPageTile/consts'
import CategoryTab from 'components/CategoryTab'
import ArrowFullLeft from 'components/Icons/ArrowFullLeft'
import ListItemSkeleton from 'components/ListItemSkeleton'

import { ScrollableContainer } from './styles'
import messages from '../../messages'

const CategoriesList = ({ ref, closeCategoriesList, mobileMenuOffset }) => {
  const navigate = useNavigate()
  const { offerDetails } = useSelector(userDataSelector)
  const { formatMessage } = useIntl()

  const {
    isCuisinesSelected,
    setCuisinesSelected,
    categories,
    cuisines,
    isLoading,
  } = useCategoriesListSelect()

  const [emblaRef] = useEmblaCarousel({
    align: 'start',
    slidesToScroll: 'auto',
  })

  const items = isCuisinesSelected ? cuisines : categories
  const DefaultIcon = isCuisinesSelected
    ? DefaultCuisinesIcon
    : DefaultCategoriesIcon

  const handleCategorySelect = category => {
    navigate(generatePath(ROUTES.PRODUCTS, { category: category.id }))
  }

  const handleCuisineClick = cuisine => {
    trackCategoriesDropdownEvent(FILTER_EVENTS.clickedCuisine, {
      cuisine: cuisine.name,
    })

    const queryParams = qs.stringify(
      {
        filters: {
          cuisineFeatures: [cuisine.id],
        },
      },
      { encode: false, arrayFormat: 'brackets' },
    )

    navigate(`${generatePath(ROUTES.PRODUCTS)}?${queryParams}`)
  }

  const handleItemSelect = item => {
    if (isCuisinesSelected) {
      handleCuisineClick(item)
    } else {
      handleCategorySelect(item)
    }
  }

  const handleCuisinesClick = () => {
    trackCategoriesDropdownEvent(NAV_BAR_EVENTS.CUISINES_CLICKED)
    setCuisinesSelected(true)
  }

  const handleMainPageClick = () => {
    trackCategoriesDropdownEvent(NAV_BAR_EVENTS.MAIN_PAGE_CLICKED)
    navigate(ROUTES.CATEGORIES)
  }

  const handleAllProductsLinkClick = () => {
    trackCategoriesDropdownEvent(NAV_BAR_EVENTS.ALL_PRODUCTS_CLICKED)
    navigate(ROUTES.ALL_PRODUCTS)
  }

  return (
    <div
      ref={ref}
      className="flex flex-col"
      data-test-id="categories-list-container"
    >
      <div className="flex py-6 pr-6 pl-4 gap-2 items-center justify-between">
        <ArrowFullLeft
          data-test-id="return-btn"
          onClick={closeCategoriesList}
        />
        <button
          type="button"
          onClick={handleMainPageClick}
          className="text-sm leading-6"
        >
          {formatMessage(messages.home)}
        </button>
      </div>
      <ScrollableContainer $mobileMenuOffset={mobileMenuOffset}>
        <div className="w-full p-4" ref={emblaRef}>
          <div className="flex *:flex-[0_0_147.5px] *:not-last:mr-4">
            {offerDetails?.hasNovelties && (
              <FilteredPageTile
                variantConfig={
                  DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.NOVELTIES]
                }
              />
            )}
            {offerDetails?.hasActiveClearances && (
              <FilteredPageTile
                variantConfig={
                  DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.CLEARANCE]
                }
              />
            )}
            {offerDetails?.hasBestsellers && (
              <FilteredPageTile
                variantConfig={
                  DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.BESTSELLERS]
                }
              />
            )}
            {offerDetails?.hasActivePromotions && (
              <FilteredPageTile
                variantConfig={
                  DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.PROMOTIONS]
                }
              />
            )}
          </div>
        </div>
        <div className="sticky top-0 bg-white">
          <div className="py-4 px-4 flex gap-6 border-t border-b border-solid border-grey-300 max-w-full overflow-x-auto">
            <CategoryTab
              title={formatMessage(messages.categoriesTab)}
              active={!isCuisinesSelected}
              onClick={() => setCuisinesSelected(false)}
            />
            {offerDetails?.hasCuisines && (
              <CategoryTab
                title={formatMessage(messages.cuisinesTab)}
                active={isCuisinesSelected}
                onClick={handleCuisinesClick}
                showNewBadge
              />
            )}
            <div className="h-[30px] bg-grey-300 ml-auto w-px shrink-0" />
            <button
              type="button"
              className="h-full flex items-start justify-start text-sm leading-6 whitespace-nowrap"
              onClick={handleAllProductsLinkClick}
            >
              {formatMessage(messages.allProducts)}
            </button>
          </div>
        </div>
        <div className="flex flex-col p-2">
          {isLoading ? (
            <>
              <ListItemSkeleton className="p-4" />
              <ListItemSkeleton className="p-4" />
            </>
          ) : (
            items.map(item => {
              const CategoryIcon = ICONS_MAP[item.id] || DefaultIcon

              return (
                <button
                  type="button"
                  className="flex justify-between p-4 gap-2"
                  key={item.id}
                  onClick={() => handleItemSelect(item)}
                >
                  <div className="flex gap-4 min-w-0">
                    <CategoryIcon />
                    <span className="text-h3 text-ellipsis overflow-hidden whitespace-nowrap">
                      {item.name}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-sm leading-6 font-medium text-grey-700">
                      {item.productCount}
                    </span>
                    <ArrowRightXS className="[&>path]:stroke-grey-700" />
                  </div>
                </button>
              )
            })
          )}
        </div>
      </ScrollableContainer>
    </div>
  )
}

export default CategoriesList
