import { useEffect, useRef } from 'react'
import debounce from 'lodash/debounce'

const useOnWindowWidthResize = ({ onChange, delay }) => {
  const prevWidthRef = useRef(window.innerWidth)

  useEffect(
    () => {
      const handleResize = () => {
        const currentWidth = window.innerWidth
        const prevWidth = prevWidthRef.current

        if (currentWidth !== prevWidth) {
          onChange({
            currentWidth,
            prevWidth,
          })

          prevWidthRef.current = currentWidth
        }
      }

      const debouncedHandleResize = debounce(handleResize, delay)

      window.addEventListener('resize', debouncedHandleResize)

      return () => {
        window.removeEventListener('resize', debouncedHandleResize)
        debouncedHandleResize.cancel()
      }
    },
    [onChange, delay],
  )
}

export default useOnWindowWidthResize
