import styled from 'styled-components'

import theme from 'theme'

export const CampaignsTileContainer = styled.div`
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  position: relative;
  gap: ${theme.spacing.sm};
  overflow: hidden;
`
