import React from 'react'

import { PageContent } from 'containers/App/LoggedInLayout/styles'

import { FixedContainer, InnerFixedWrapper } from './styles'

export const FixedBottom = ({ children, ref, isFixed }) => (
  <FixedContainer ref={ref} {...{ isFixed }}>
    <PageContent>
      <InnerFixedWrapper {...{ isFixed }}>{children}</InnerFixedWrapper>
    </PageContent>
  </FixedContainer>
)
