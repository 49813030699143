/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/stable'
import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import TagManager from 'react-gtm-module'
import Modal from 'react-modal'
import { datadogRum } from '@datadog/browser-rum'
import dayjs from 'dayjs'
import './dayjsConfig'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  unstable_HistoryRouter as Router,
} from 'react-router'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import NiceModal from '@ebay/nice-modal-react'
import { ThemeProvider } from 'styled-components'
import theme from 'theme'

import history from 'utils/history'

import './globalStyles/index.css'

// Import root app
import App from 'containers/App'

import RemoteConfigProvider from 'containers/RemoteConfigProvider'

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider'

import pkg from '../package.json'

// Load the favicon
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico'
/* eslint-enable import/no-unresolved, import/extensions */

// Import i18n messages
import { translationMessages, DEFAULT_LOCALE } from './i18n'
import { API_URL, GTM_ID, SENTRY_DSN, SENTRY_ENV } from './consts'
import store from './store'

if (SENTRY_DSN && SENTRY_ENV) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: pkg.version,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: SENTRY_ENV === 'prod' ? 0.1 : 0,
    replaysOnErrorSampleRate: SENTRY_ENV === 'prod' ? 1.0 : 0,
  })
}

const tagManagerArgs = {
  gtmId: GTM_ID,
}

TagManager.initialize(tagManagerArgs)

const {
  DATADOG_RUM_APP_ID,
  DATADOG_RUM_TOKEN,
  DATADOG_SERVICE,
  DATADOG_ENV,
  DATADOG_SITE,
} = window._env_ // eslint-disable-line no-underscore-dangle
if (DATADOG_RUM_APP_ID && DATADOG_RUM_TOKEN) {
  datadogRum.init({
    applicationId: DATADOG_RUM_APP_ID,
    clientToken: DATADOG_RUM_TOKEN,
    service: DATADOG_SERVICE,
    env: DATADOG_ENV,
    version: pkg.version,
    site: DATADOG_SITE,
    sampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [API_URL],
  })
}

dayjs.locale(DEFAULT_LOCALE)

// hide tidio chat on initial render
if (window.tidioChatApi) {
  window.tidioChatApi.on('ready', () => window.tidioChatApi.hide())
} else {
  document.addEventListener('tidioChat-ready', () => window.tidioChatApi.hide())
}

Modal.setAppElement('#app')
// Create redux store with history
const MOUNT_NODE = document.getElementById('app')
const root = createRoot(MOUNT_NODE)

const render = messages => {
  root.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <Router history={history}>
          <RemoteConfigProvider defaults={{}}>
            <ThemeProvider theme={theme}>
              <NiceModal.Provider>
                <App />
              </NiceModal.Provider>
            </ThemeProvider>
          </RemoteConfigProvider>
        </Router>
      </LanguageProvider>
    </Provider>,
  )
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    // eslint-disable-next-line import/extensions
    .then(() => Promise.all([import('intl/locale-data/jsonp/pl.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}
