import React from 'react'

import { cn } from 'utils'
import { CircleAlert, InfoCircleSmall as HintIcon } from 'components/Icons'

const TextInput = ({
  name,
  id = name,
  htmlFor = name,
  label,
  value,
  containerTestId,
  readOnly,
  placeholder,
  handleChange,
  handleBlur,
  className,
  inputClassName,
  labelClassName,
  testId,
  isError,
  errorText,
  hint,
  disabled,
  type = 'text',
}) => (
  <div
    data-test-id={containerTestId}
    className={cn('flex flex-col w-full gap-2 min-w-0', className)}
  >
    {label && (
      <label htmlFor={htmlFor} className={cn('text-12', labelClassName)}>
        {label}
      </label>
    )}
    <input
      id={id}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={!isError && placeholder}
      readOnly={readOnly}
      data-test-id={testId}
      disabled={disabled}
      type={type}
      className={cn(
        'bg-grey-150 py-3 px-4 rounded-lg outline-hidden text-base placeholder-grey-600 hover:bg-grey-400 focus:bg-grey-400 duration-300 autofill-valid',
        inputClassName,
        isError &&
          'autofill-invalid bg-red-100 text-red-700 focus:bg-red-100 hover:bg-red-100',
      )}
    />

    {((isError && errorText) || hint) && (
      <div className="flex gap-2">
        {errorText ? <CircleAlert /> : <HintIcon />}
        <span
          className={cn(
            'text-label-11',
            errorText ? 'text-red-700' : 'text-grey-800',
          )}
        >
          {errorText || hint}
        </span>
      </div>
    )}
  </div>
)

export default TextInput
