import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { TinyText } from 'components/Text'

export const Container = styled(Flex)`
  align-items: center;
  height: 100%;
  min-height: ${theme.spacing.lg};
  cursor: pointer;
  padding: 0 ${theme.spacing.base};

  ${p =>
    p.bgHoverColor &&
    `
    :hover {
      background: ${p.bgHoverColor};
    }
  `};
`

export const PayDescription = styled.div`
  flex: 1;
`

export const TotalContainer = styled.div`
  text-align: right;
  margin-right: ${theme.spacing.sm};
  margin-left: ${theme.spacing.base};
  flex-shrink: 0;
`

export const GrossLabel = styled(TinyText)`
  line-height: 20px;
`
