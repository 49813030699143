import React from 'react'
import { identity } from 'lodash/identity'

import theme from 'theme'
import { ArrowDownFilter } from 'components/Icons'
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from 'components/Popup'
import {
  BACKGROUND_OVERLAY_DARK,
  BACKGROUND_OVERLAY_TRANSPARENT,
} from 'components/Popup/transitions'
import XSmall from 'components/Icons/XSmall'
import { cn } from 'utils'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'

const Dropdown = ({
  onDropdownShow = identity,
  onDropdownHide = identity,
  title,
  children,
  testId,
  selectedFiltersLength,
  onClearSelected,
  disabled,
  width = 320,
}) => {
  const onClearClick = e => {
    e.stopPropagation()
    onClearSelected(e)
  }

  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)

  return (
    <Popover
      onOpen={onDropdownShow}
      onClose={onDropdownHide}
      placement="bottom-start"
      disableArrow
      offsetValues={{ main: 4 }}
    >
      <PopoverTrigger asChild>
        <button
          type="button"
          data-test-id={testId}
          disabled={disabled}
          className="group flex gap-1.5 items-center whitespace-nowrap px-2 rounded-lg hover:bg-grey-150 data-[state=open]:bg-green-600 data-[state=open]:text-white data-[state=open]:[&_path]:stroke-white data-[state=open]:[&_svg]:rotate-180 data-[state=open]:[&_.counter-container]:bg-green-700 disabled:opacity-30 disabled:hover:bg-transparent"
        >
          <span className="text-label-11">{title}</span>
          {selectedFiltersLength > 0 && (
            <PopoverClose
              type="button"
              onClick={onClearClick}
              className="counter-container flex items-center py-px px-1 bg-green-600 rounded-[5px] hover:bg-green-500 hover:[&_.counter]:hidden hover:[&_.clear]:block"
            >
              <span className="counter text-label-11 text-white mx-1">
                {selectedFiltersLength}
              </span>
              <div className="clear hidden">
                <XSmall color={theme.colors.white} />
              </div>
            </PopoverClose>
          )}
          <ArrowDownFilter className={cn('[&_path]:stroke-blue-900')} />
        </button>
      </PopoverTrigger>
      <PopoverContent
        withOverlay
        asBottomModal={!isTablet}
        transitions={
          isTablet ? BACKGROUND_OVERLAY_TRANSPARENT : BACKGROUND_OVERLAY_DARK
        }
      >
        <div
          data-test-id="filters-dropdown-content"
          className="bg-white shadow-modal rounded-2xl overflow-hidden"
          style={{
            width: isTablet ? width : '100%',
          }}
        >
          {children}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default Dropdown
