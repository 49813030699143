import { find, isNil, toNumber, round, toString } from 'lodash'

import { CURRENCY } from 'consts'
import { getBadgeProductLabels } from 'components/Product/Badge/utils'
import { FEATURE_TYPES } from 'components/Product/Badge/consts'
import {
  isLoweredPrice,
  REPLACEMENT_STATUS,
  getReplacementStatus,
} from 'components/Product/utils'

import { retailTrackPurchaseComplete } from './retail'
import { EVENTS, AVAILABILITY_STATUS } from './consts'

export const normalizePrice = price => {
  if (!price) return 0

  return round(toNumber(price) / 100, 2)
}

export const findUnitData = (unitsOfMeasure, unitOfMeasure) => {
  const unitData = find(unitsOfMeasure, { unitOfMeasure })
  if (!unitData) {
    return null
  }

  return unitData
}

export const getProductDiscount = ({ promotion }, unitData) => {
  if (isLoweredPrice(promotion)) {
    return unitData.priceNet - promotion.reward.priceNet
  }
  return undefined
}

export const getFormattedProductParams = (product, unitData, itemModelId) => {
  if (!product || !unitData) return null

  const { promotion, features } = product

  const promoId = promotion?.id
  const promoName = promotion?.title
  const labels = getBadgeProductLabels(features)
  const recommendedFeature = features.find(
    ({ type }) => type === FEATURE_TYPES.RECOMMENDATION,
  )

  return {
    item_id: product.id,
    item_name: product.name,
    item_brand: product.brand,
    item_variant: unitData.unitOfMeasure,
    item_category: product.categoryName || undefined,
    item_category2: product.groupName || undefined,
    item_category3: product.subgroupName || undefined,
    item_category4: labels[0],
    item_category5: itemModelId || recommendedFeature?.value,
    promotion_id: promoId || undefined,
    promotion_name: promoName || undefined,
  }
}

export const makeCategoryTree = ({ categoryName, groupName, subgroupName }) => {
  const group = groupName ? ` - ${groupName}` : ''
  const subgroup = subgroupName ? ` - ${subgroupName}` : ''

  return categoryName + group + subgroup
}

/*
* Generic events handlers
*/

export const trackSimpleEvent = (event, params = {}) => {
  window.dataLayer.push({ event, ...params })
}

export const trackPageView = pageTitle => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: EVENTS.PAGE_VIEW,
    pagePath: window.location.href,
    pageTitle,
  })
}

/*
* Mappers
*/

export const productMapper = ({
  name,
  id,
  brand,
  category,
  list,
  unitData,
  index,
  quantity,
  categoryName,
  groupName,
  subgroupName,
  promotion,
}) => {
  const tree = makeCategoryTree({ categoryName, groupName, subgroupName })
  const categoryString = categoryName ? tree : category

  return {
    name,
    id,
    brand,
    price: normalizePrice(unitData.priceNet * unitData.multiplier),
    variant: unitData.unitOfMeasureDescription,
    ...(!isNil(index) && { position: index + 1 }),
    ...(list && { list }),
    ...(categoryString && { category: categoryString }),
    ...(quantity && { quantity }),
    ...(promotion && { promotion: promotion.title }),
  }
}

export const makeCartProductsMapper = ({ category, list } = {}) => (
  { unitOfMeasure, productId, product, ...rootProps },
  index,
) => {
  const unitData = findUnitData(product.unitsOfMeasure, unitOfMeasure)
  if (!unitData) {
    return null
  }

  return productMapper({
    category,
    list,
    id: productId,
    unitData,
    index,
    ...rootProps,
    ...product,
  })
}

export const makePurchaseProductsMapper = () => ({
  unitOfMeasure,
  product,
  quantity,
}) => {
  const unitData = findUnitData(product.unitsOfMeasure, unitOfMeasure)
  if (!unitData) {
    return null
  }

  return {
    ...getFormattedProductParams(product, unitData),
    price: normalizePrice(unitData.priceNet * unitData.multiplier),
    quantity,
  }
}

/*
* Cart Handlers
*/

export const mapCartItems = (items, mapper) =>
  items.reduce(
    (acc, { product, units }) => [
      ...acc,
      ...units.map(unit =>
        mapper({
          productId: product.id,
          product,
          ...unit,
        }),
      ),
    ],
    [],
  )

export const purchaseHandler = async ({
  items,
  totalGross,
  totalNet,
  orderNumber,
  customerNo,
  orders,
}) => {
  const currency = CURRENCY
  const value = normalizePrice(totalGross)
  const tax = normalizePrice(totalGross - totalNet)

  const purchaseProductMapper = makePurchaseProductsMapper()
  trackEcommerceEvent(EVENTS.PURCHASE, {
    transaction_id: orderNumber,
    customer_no: customerNo,
    items: mapCartItems(items, purchaseProductMapper),
    value,
    tax,
    currency,
  })

  await retailTrackPurchaseComplete({
    unifiedOrderNumber: orderNumber,
    orders,
    value,
    tax,
    currency,
  })
}

export const trackEcommerceEvent = (event, ecommerce) => {
  // clear the previous ecommerce object
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event,
    ecommerce,
  })
}

export const formatItemListParams = (itemListId, itemListName) =>
  itemListId
    ? {
        item_list_id: itemListId ? toString(itemListId) : undefined,
        item_list_name: itemListName || undefined,
      }
    : {}

export const getAvailabilityStatusParam = ({ product, unitData, userData }) => {
  const replacementStatus = getReplacementStatus({
    product,
    unitOfMeasureObj: unitData,
    currentUser: userData,
  })

  if (replacementStatus === REPLACEMENT_STATUS.OUT_OF_OFFER) {
    return AVAILABILITY_STATUS.OUT_OF_OFFER
  }
  if (replacementStatus === REPLACEMENT_STATUS.OUT_OF_STOCK) {
    return AVAILABILITY_STATUS.OUT_OF_STOCK
  }
  if (replacementStatus === REPLACEMENT_STATUS.AVAILABLE_SOON) {
    return AVAILABILITY_STATUS.AVAILABLE_SOON
  }

  return undefined
}
