import { isEmpty } from 'lodash/fp'

import { rtkApi } from 'services/api/rtkApi'
import { getFilters } from 'containers/Products/Filters/api'
import {
  formatFiltersParams,
  provideProductsQueryTags,
} from 'containers/Products/utils'

export const RTK_CAMPAIGN_DETAILS_TAG = 'CampaignDetails'
export const RTK_CAMPAIGN_FILTERS_TAG = 'CampaignFilters'

export const campaignApi = rtkApi
  .enhanceEndpoints({
    addTagTypes: [RTK_CAMPAIGN_DETAILS_TAG, RTK_CAMPAIGN_FILTERS_TAG],
  })
  .injectEndpoints({
    endpoints: build => ({
      getCampaignProducts: build.query({
        query: ({ id, perPage = 24, page = 1, selectedFiltersParams }) => {
          const queryParams = formatFiltersParams({
            per_page: perPage,
            page,
            ...selectedFiltersParams,
          })
          return {
            url: `me/campaigns/${id}/products?${queryParams}`,
          }
        },
        transformResponse: (res, meta, arg) => ({
          products: res.products,
          pagination: res.meta.pagination,
          isFiltered: !isEmpty(arg.selectedFiltersParams),
        }),
        providesTags: result => provideProductsQueryTags(result),
      }),
      getCampaignFilters: build.query({
        query: ({ id, selectedFiltersParams } = {}) =>
          getFilters({ campaignId: id, selectedFiltersParams }),
        providesTags: (result, error, params) => [
          { type: RTK_CAMPAIGN_FILTERS_TAG, id: params.id },
        ],
      }),
      getCampaignDetails: build.query({
        query: ({ id }) => ({
          url: `me/campaigns/${id}`,
        }),
        providesTags: (result, error, params) => [
          { type: RTK_CAMPAIGN_DETAILS_TAG, id: params.id },
        ],
      }),
    }),
  })

export const {
  useLazyGetCampaignFiltersQuery,
  useLazyGetCampaignProductsQuery,
  useGetCampaignFiltersQuery,
  useGetCampaignDetailsQuery,
  useGetCampaignProductsQuery,
} = campaignApi
