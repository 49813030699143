import React from 'react'
import { generatePath } from 'react-router'

import { ROUTES } from 'consts'
import { Link } from 'components/Text'

import SectionColumn from 'containers/App/LoggedInLayout/Footer/SectionColumn'
import messages from '../messages'
import { FooterLinkText } from '../styles'

import { InformationLinks } from './styles'

const INFO_CONFIG = [
  {
    msg: 'termsOfUse',
    to: ROUTES.TERMS,
  },
  {
    msg: 'privacyPolicy',
    to: ROUTES.PRIVACY,
  },
  {
    msg: 'knowledgeBase',
    to: generatePath(ROUTES.KNOWLEDGE_BASE),
  },
]

const Information = ({ formatMessage }) => (
  <SectionColumn title={formatMessage(messages.informationTitle)}>
    <InformationLinks>
      {INFO_CONFIG.map(({ msg, to }) => (
        <Link key={msg} {...{ to }}>
          <FooterLinkText>{formatMessage(messages[msg])}</FooterLinkText>
        </Link>
      ))}
    </InformationLinks>
  </SectionColumn>
)

export default Information
