import React from 'react'

import { cn } from 'utils'

const CarouselTracker = ({ snaps = [], selectedSnapIndex }) => {
  if (snaps.length < 1) {
    return null
  }

  return (
    <div className="flex overflow-hidden justify-center items-center gap-1">
      {snaps.map((snap, index) => (
        <div
          className={cn(
            'h-0.5 w-3 rounded-full bg-[#D2DCE7] transition ease-out',
            selectedSnapIndex === index && 'bg-blue-900',
          )}
          key={snap}
          data-test-id="carousel-tracker-dot"
        />
      ))}
    </div>
  )
}

export default CarouselTracker
