import React, { useEffect } from 'react'
import usePrevious from 'use-previous'
import useEmblaCarousel from 'embla-carousel-react'

import { ProductBox } from 'components/Product'
import { ProductTileMultipleSkeleton } from 'components/Skeletons/ProductTileSkeleton'
import { usePrevNextButtons, useSnapDetails } from 'components/Carousel/utils'
import SkeletonBlock from 'components/Skeletons/SkeletonBlock'
import DefaultCarouselControls from 'components/Carousel/DefaultCarouselControls'
import { trackViewItemList } from 'services/analytics'
import CarouselBorderFade from 'components/Carousel/CarouselBorderFade'
import { cn } from 'utils'

export const PRODUCTS_CAROUSEL_ID = 'suggestions-section'

const ProductsCarousel = ({
  ref,
  products,
  isLoading,
  itemModelId,
  attributionToken,
  itemListId,
  itemListName,
  title,
  testId = 'products-carousel',
  className,
  fadeClassName,
}) => {
  const prevIsLoading = usePrevious(isLoading)

  useEffect(
    () => {
      if (!isLoading && prevIsLoading) {
        trackViewItemList({
          products,
          itemListId,
          itemListName,
          itemModelId,
        })
      }
    },
    [isLoading, prevIsLoading],
  )

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    skipSnaps: true,
    slidesToScroll: 'auto',
  })

  const { selectedIndex, scrollSnaps } = useSnapDetails(emblaApi)
  const {
    onPrevButtonClick,
    onNextButtonClick,
    nextBtnDisabled,
    prevBtnDisabled,
  } = usePrevNextButtons(emblaApi)

  if (!isLoading && !products.length) {
    return null
  }

  return (
    <div
      ref={ref}
      className={cn(
        'relative flex flex-col gap-8 w-full overflow-hidden',
        className,
      )}
      id={PRODUCTS_CAROUSEL_ID}
      data-test-id={testId}
    >
      <CarouselBorderFade
        rightBottomClassName={fadeClassName}
        leftTopClassName={fadeClassName}
      />
      {isLoading ? (
        <SkeletonBlock />
      ) : (
        <h1 className="text-m-h2 md:text-h1">{title}</h1>
      )}
      <div className="w-full flex flex-col gap-2">
        <div className="w-full" ref={emblaRef}>
          <div className="flex *:overflow-hidden *:max-w-[221px]! *:not-last:mr-4 *:flex-[1_0_60%] min-[360px]:*:basis-[57%] min-[420px]:*:basis-[45%] min-[500px]:*:basis-[37%] min-[660px]:*:basis-[30%] min-[800px]:*:basis-[27%] min-[840px]:*:basis-[23%] lg:*:basis-[calc((100%-64px)/5)] min-[1200px]:*:basis-[calc((100%-80px)/6)] min-[1392px]:*:basis-[calc((100%-96px)/7)]">
            {isLoading ? (
              <ProductTileMultipleSkeleton length={7} />
            ) : (
              products.map((product, index) => (
                <ProductBox
                  className="w-full max-w-full!"
                  key={product.id}
                  {...product}
                  index={index}
                  product={product}
                  itemListId={itemListId}
                  itemListName={itemListName}
                  itemModelId={itemModelId}
                  attributionToken={attributionToken}
                />
              ))
            )}
          </div>
        </div>
        {scrollSnaps.length > 1 && (
          <DefaultCarouselControls
            handleNextClick={onNextButtonClick}
            handlePrevClick={onPrevButtonClick}
            nextBtnDisabled={nextBtnDisabled}
            prevBtnDisabled={prevBtnDisabled}
            snaps={scrollSnaps}
            selectedSnapIndex={selectedIndex}
          />
        )}
      </div>
    </div>
  )
}

export default ProductsCarousel
