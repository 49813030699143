import React from 'react'
import { useSelector } from 'react-redux'
import { get, isNull } from 'lodash/fp'

import { userDataSelector } from 'containers/UserInfo/selectors'
import { deliveryDatesByProductIdsSelector } from 'containers/Cart/selectors'
import Tag from 'components/Tag'
import { Badge, BADGE_DIRECTION, BADGE_STATUS } from 'components/Product/Badge'
import { SecondaryTextSuccess } from 'components/Text'
import {
  REPLACEMENT_STATUS,
  getReplacementStatus,
  shouldProductBeAvailableSoon,
} from 'components/Product/utils'

import { getAvailabilityStatus, getUnavailableMsg } from './utils'
import { STOCK_TYPES, AVAILABILITY_MODE, STATUS_TAG_MAP } from './consts'
import { TagContainer, AvailabilityRowText } from './styles'

const { AVAILABLE } = STOCK_TYPES

const DefaultWrapComponent = ({ children }) => children

const Availability = ({
  product: {
    id,
    labelName,
    labelType,
    closestDeliveryTime,
    deadlineOrderTime,
    receiptDate,
    nonStock,
    active,
    licenseMissing,
    withdrawingSoon,
  },
  product,
  promotionName,
  unitOfMeasureObj,
  showPromo,
  suppressSecondary,
  mode = AVAILABILITY_MODE.BADGE,
  direction = BADGE_DIRECTION.LEFT,
  WrapComponent = DefaultWrapComponent,
}) => {
  if (!active || !unitOfMeasureObj) return null

  const userData = useSelector(userDataSelector)
  const productDeliveryOptions = useSelector(
    deliveryDatesByProductIdsSelector([id]),
  )
  const availableSoon = shouldProductBeAvailableSoon(product, userData)

  const replacementStatus = getReplacementStatus({
    product,
    unitOfMeasureObj,
    currentUser: userData,
  })

  const outOfStock = replacementStatus === REPLACEMENT_STATUS.OUT_OF_STOCK
  const promo = showPromo ? product?.promotion : null
  const noAvailability = labelType === AVAILABLE && !outOfStock
  const promoName =
    !isNull(promotionName) && (promotionName || get('title')(promo))

  if (noAvailability && promoName) {
    return (
      <WrapComponent>
        <SecondaryTextSuccess strongBold>{promoName}</SecondaryTextSuccess>
      </WrapComponent>
    )
  }

  if ((noAvailability && !availableSoon) || licenseMissing) {
    return null
  }

  const isSoonAvailable =
    replacementStatus === REPLACEMENT_STATUS.AVAILABLE_SOON
  const status = getAvailabilityStatus({
    outOfStock,
    closestDeliveryTime,
    isSoonAvailable,
  })
  const { message } = getUnavailableMsg({
    closestDeliveryTime,
    withdrawingSoon,
    deadlineOrderTime,
    receiptDate,
    nonStock,
    labelName,
    unitOfMeasureObj,
    isSoonAvailable,
    productDeliveryOptions,
  })

  if (
    suppressSecondary &&
    (status !== BADGE_STATUS.ERROR && status !== BADGE_STATUS.SUCCESS)
  ) {
    return null
  }

  if (!message) return null

  if (mode === AVAILABILITY_MODE.TEXT) {
    return (
      <WrapComponent>
        <TagContainer>
          <SecondaryTextSuccess strongBold>
            <Tag textMode {...{ message }} color={STATUS_TAG_MAP[status]} />
            {promoName && ` • ${promoName}`}
          </SecondaryTextSuccess>
        </TagContainer>
      </WrapComponent>
    )
  }

  if (mode === AVAILABILITY_MODE.ROW) {
    return (
      <WrapComponent>
        <AvailabilityRowText>{message}</AvailabilityRowText>
      </WrapComponent>
    )
  }

  return (
    <WrapComponent>
      <Badge elements={[message]} {...{ status, direction }} />
    </WrapComponent>
  )
}

export default Availability
export { AVAILABILITY_MODE }
