import { useCallback, useEffect, useState } from 'react'

export const useSnapDetails = (emblaApi, { slideChangeCallback } = {}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const onClick = useCallback(
    index => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi],
  )

  const onInit = useCallback(api => {
    setScrollSnaps(api.scrollSnapList())
  }, [])

  const onSelect = useCallback(api => {
    setSelectedIndex(api.selectedScrollSnap())
  }, [])

  const onSelectCallback = useCallback(api => {
    slideChangeCallback?.(api.selectedScrollSnap())
  }, [])

  useEffect(
    () => {
      if (!emblaApi) return

      onInit(emblaApi)
      onSelect(emblaApi)
      emblaApi
        .on('reInit', onInit)
        .on('reInit', onSelect)
        .on('select', onSelect)
        .on('select', onSelectCallback)
    },
    [emblaApi, onInit, onSelect],
  )

  return {
    selectedIndex,
    scrollSnaps,
    onClick,
  }
}

export const usePrevNextButtons = emblaApi => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(
    () => {
      if (!emblaApi) return
      emblaApi.scrollPrev()
      emblaApi.plugins()?.autoplay?.stop()
    },
    [emblaApi],
  )

  const onNextButtonClick = useCallback(
    () => {
      if (!emblaApi) return
      emblaApi.scrollNext()
      emblaApi.plugins()?.autoplay?.stop()
    },
    [emblaApi],
  )

  const onSelect = useCallback(api => {
    setPrevBtnDisabled(!api.canScrollPrev())
    setNextBtnDisabled(!api.canScrollNext())
  }, [])

  useEffect(
    () => {
      if (!emblaApi) return

      onSelect(emblaApi)
      emblaApi.on('reInit', onSelect).on('select', onSelect)
    },
    [emblaApi, onSelect],
  )

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  }
}
