import React, { useState, useEffect } from 'react'
import { compose, random } from 'lodash/fp'
import { Route, Routes } from 'react-router'

import { RESET_PASSWORD_ROUTES, ROUTES } from 'consts'
import injectSaga from 'utils/injectSaga'
import withModal from 'hocs/withModal'

import {
  MODAL_SOMETHING_WENT_WRONG,
  MODAL_OFFLINE,
} from 'containers/App/modalTypes'
import NewsletterUnsubscriptionConfirm from 'containers/Profile/Newsletter/EmailConfirm/UnsubscriptionConfirm'
import SomethingWentWrong from 'components/modal/SomethingWentWrong'
import OfflineModal from 'components/modal/OfflineModal'

import NotFoundPage from 'views/NotFoundPage'
import Login from 'views/Login'
import Dashboard from 'views/Dashboard'
import Contact from 'views/Contact'
import Categories from 'views/Categories'
import Cart from 'views/Cart'
import CartMissingItems from 'views/CartMissingItems'
import OrderSummary from 'views/OrderSummary'
import Templates from 'views/Templates'
import SignUp from 'views/SignUp'
import SetPassword from 'views/SetPassword'
import EmailConfirm from 'views/EmailConfirm'
import ChangePasswordConfirm from 'views/Profile/ChangePasswordConfirm'
import ResetPasswordConfirm from 'views/Profile/ResetPassword/Confirm'
import ResetPasswordInit from 'views/Profile/ResetPassword/Init'
import CampaignDetails from 'views/CampaignDetails'
import Terms from 'views/Terms'
import PrivacyPolicy from 'views/PrivacyPolicy'
import TemplateNotAddedItems from 'views/TemplateNotAddedItems'
import Location from 'views/Location'
import Promotions from 'views/Promotions'
import Products from 'views/Products'
import ProductDetails from 'views/ProductDetails'
import FailureMigrate from 'views/SignUpMigrate/Failure'
import SuccessMigrate from 'views/SignUpMigrate/Success'
import SignUpMigrateWrapper from 'views/SignUpMigrate'
import SignUpMigrate from 'views/SignUpMigrate/SignUpMigrate'
import { PROFILE_ROUTES } from 'containers/Profile/consts'
import ChangePassword from 'views/Profile/ChangePassword'
import ProfileWrapper from 'views/Profile'
import Profile from 'views/Profile/Profile'
import NewsletterFeedback from 'views/Profile/Newsletter/Feedback'
import NewsletterUnsubscribeSuccess from 'views/Profile/Newsletter/UnsubscribeSuccess'
import { ORDERS_ROUTES } from 'views/Orders/consts'
import OrdersList from 'views/Orders/List'
import OrderDetails from 'views/Orders/Details'
import { INVOICES_ROUTES } from 'views/Invoices/consts'
import PayProcessing from 'views/Invoices/PayProcessing'
import List from 'views/Invoices/List'
import Activate2FA from 'views/Profile/Activate2FA'
import Deactivate2FA from 'views/Profile/Deactivate2FA'
import KnowledgeBase from 'views/KnowledgeBase'
import Redirect from 'views/Redirect'

import Maintenance from 'containers/App/Maintenance'
import { useFirestoreDoc } from 'hooks'
import AuthGuard from './AuthGuard'
import LoggedInLayout from './LoggedInLayout'
import ScrollToTop from './ScrollToTop'
import ModalBase from './ModalBase'
import { APP_REDUCER_NAME } from './reducer/consts'
import saga from './sagas/globalSaga'

function App() {
  const [isMaintenanceEnabled, setMaintenanceEnabled] = useState(false)
  const maintenanceScreen = useFirestoreDoc({
    docRef: 'maintenance',
    defaultValue: { enabled: false },
    maxDelay: 3000,
  })

  // logic below for solving issue of simultaneous fetches for all users after disabling maintenance
  useEffect(
    () => {
      if (maintenanceScreen) {
        if (maintenanceScreen.enabled) {
          setMaintenanceEnabled(true)
        } else {
          const disableDelay = random(
            maintenanceScreen.minDisableDelay,
            maintenanceScreen.maxDisableDelay,
          )
          setTimeout(() => setMaintenanceEnabled(false), disableDelay)
        }
      }
    },
    [maintenanceScreen],
  )

  if (!maintenanceScreen) {
    return null
  }

  return (
    <AuthGuard>
      {isMaintenanceEnabled ? (
        <Maintenance config={maintenanceScreen} />
      ) : (
        <>
          <LoggedInLayout>
            <ModalBase />
            <Routes>
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
              <Route path={ROUTES.CONTACT} element={<Contact />} />
              <Route path={ROUTES.CATEGORIES} element={<Categories />} />
              <Route
                path={ROUTES.CAMPAIGN_DETAILS}
                element={<CampaignDetails />}
              />
              <Route path={ROUTES.PRODUCTS} element={<Products />} />
              <Route path={ROUTES.SEARCH_RESULTS} element={<Products />} />
              <Route
                path={ROUTES.PRODUCT_DETAILS}
                element={<ProductDetails />}
              />
              <Route path={ROUTES.CART} element={<Cart />} />
              <Route
                path={ROUTES.CART_MISSING_ITEMS}
                element={<CartMissingItems />}
              />
              <Route path={ROUTES.ORDER_SUMMARY} element={<OrderSummary />} />
              <Route path={ROUTES.TEMPLATE_DETAILS} element={<Templates />} />
              <Route path={ROUTES.SIGN_UP} element={<SignUp />} />

              <Route path="migrate" element={<SignUpMigrateWrapper />}>
                <Route path="success" element={<SuccessMigrate />} />
                <Route path="failure" element={<FailureMigrate />} />
                <Route path=":token" element={<SignUpMigrate />} />
              </Route>

              <Route
                path={ROUTES.EMAIL_CONFIRMATION}
                element={<EmailConfirm />}
              />
              <Route path={ROUTES.SET_PASSWORD} element={<SetPassword />} />

              <Route path={ROUTES.PROFILE} element={<ProfileWrapper />}>
                <Route path={PROFILE_ROUTES.PROFILE} element={<Profile />} />
                <Route
                  path={PROFILE_ROUTES.CHANGE_PASSWORD}
                  element={<ChangePassword />}
                />
                <Route
                  path={PROFILE_ROUTES.NEWSLETTER_FEEDBACK}
                  element={<NewsletterFeedback />}
                />
                <Route
                  path={PROFILE_ROUTES.NEWSLETTER_UNSUBSCRIBE_SUCCESS}
                  element={<NewsletterUnsubscribeSuccess />}
                />
              </Route>
              {/* outside main routes to avoid the wrapper */}
              <Route
                path={PROFILE_ROUTES.ACTIVATE_2FA}
                element={<Activate2FA />}
              />
              <Route
                path={PROFILE_ROUTES.DEACTIVATE_2FA}
                element={<Deactivate2FA />}
              />

              <Route
                path={ROUTES.CHANGE_PASSWORD_CONFIRM}
                element={<ChangePasswordConfirm />}
              />
              <Route
                path={RESET_PASSWORD_ROUTES.CONFIRM}
                element={<ResetPasswordConfirm />}
              />
              <Route
                path={RESET_PASSWORD_ROUTES.INIT}
                element={<ResetPasswordInit />}
              />
              <Route path={ROUTES.TERMS} element={<Terms />} />
              <Route path={ROUTES.PRIVACY} element={<PrivacyPolicy />} />
              <Route
                path={ROUTES.NOT_ALL_ADDED}
                element={<TemplateNotAddedItems />}
              />
              <Route path={ROUTES.SET_LOCATION} element={<Location />} />
              <Route path={ROUTES.CHANGE_LOCATION} element={<Location />} />
              <Route
                path={ROUTES.NEWSLETTER_UNSUBSCRIBE_EMAIL_CONFIRM}
                element={<NewsletterUnsubscriptionConfirm />}
              />
              <Route path={ORDERS_ROUTES.LIST} element={<OrdersList />} />
              <Route path={ORDERS_ROUTES.DETAILS} element={<OrderDetails />} />

              <Route path={INVOICES_ROUTES.ROOT} element={<List />} />
              <Route
                path={INVOICES_ROUTES.PAY_PROCESSING}
                element={<PayProcessing />}
              />

              <Route path={ROUTES.PROMOTIONS.ROOT} element={<Promotions />} />

              <Route path={ROUTES.KNOWLEDGE_BASE} element={<KnowledgeBase />} />

              <Route path={ROUTES.CUSTOMER_SWITCH} element={<Redirect />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </LoggedInLayout>

          <ScrollToTop />
        </>
      )}
    </AuthGuard>
  )
}

export default compose(
  injectSaga({ key: APP_REDUCER_NAME, saga }),
  withModal(SomethingWentWrong, MODAL_SOMETHING_WENT_WRONG),
  withModal(OfflineModal, MODAL_OFFLINE),
  React.memo,
)(App)
