import { rtkApi } from 'services/api/rtkApi'

export const RTK_DELIVERIES_TAG = 'deliveries'

export const deliveriesApi = rtkApi
  .enhanceEndpoints({
    addTagTypes: [RTK_DELIVERIES_TAG],
  })
  .injectEndpoints({
    endpoints: build => ({
      getAllDeliveryDates: build.query({
        query: () => ({
          url: 'me/delivery_dates',
        }),
        providesTags: [RTK_DELIVERIES_TAG],
      }),
    }),
  })

export const { useGetAllDeliveryDatesQuery } = deliveriesApi
