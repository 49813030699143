import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import { useIntl } from 'react-intl'

import { usePrevNextButtons, useSnapDetails } from 'components/Carousel/utils'
import { campaignsActions } from 'containers/Campaigns/actions'
import { selectCampaignsData } from 'containers/Campaigns/selectors'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { generateCampaignPath } from 'containers/Promotions/utils'
import { trackCampaignBannerClicked } from 'services/analytics/campaigns'

import { CampaignsTileContainer } from './styles'
import CarouselControls from './CarouselControls'
import campaignPlaceholderDesktop from './assets/campaing_placeholder_bg.png'
import messages from './messages'

const CampaignTile = () => {
  const isMobile = !useOfMinWidth(APP_BREAKPOINTS.TABLET_START)

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    Autoplay({ delay: 5000 }),
  ])
  const { selectedIndex, scrollSnaps } = useSnapDetails(emblaApi)
  const { onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(campaignsActions.delta())
  }, [])

  const campaigns = useSelector(selectCampaignsData)
  const { customerNo } = useSelector(userDataSelector)

  const { formatMessage } = useIntl()

  if (!campaigns?.length && isMobile) {
    return null
  }

  if (campaigns?.length) {
    return (
      <CampaignsTileContainer data-test-id="campaigns-tile-container">
        <div className="overflow-hidden rounded-2xl" ref={emblaRef}>
          <div className="flex max-h-[500px] lg:h-[440px]">
            {campaigns.map(({ imageUrl, id, picture, title }) => {
              const imgUrl = isMobile ? picture.urlMobile : picture.urlWeb

              return (
                <RouterLink
                  className="flex-shrink-0 basis-full"
                  key={id}
                  to={generateCampaignPath(id)}
                  onClick={() =>
                    trackCampaignBannerClicked({ title, customerNo, id })
                  }
                >
                  <img
                    className="object-cover object-left-bottom h-full w-full"
                    alt="campaign"
                    src={imgUrl || imageUrl}
                  />
                </RouterLink>
              )
            })}
          </div>
        </div>
        <CarouselControls
          handleNextClick={onNextButtonClick}
          handlePrevClick={onPrevButtonClick}
          snaps={scrollSnaps}
          selectedSnapIndex={selectedIndex}
        />
      </CampaignsTileContainer>
    )
  }

  return (
    <CampaignsTileContainer data-test-id="campaigns-tile-container">
      <img
        className="object-cover object-left-bottom w-full max-h-[500px] lg:h-[440px]"
        alt="welcome banner"
        src={campaignPlaceholderDesktop}
      />
      <div className="px-6 pt-6 pb-10 absolute rounded-2xl left-6 bottom-6 w-[396px] bg-green-600">
        <span className="text-h1 text-white">
          {formatMessage(messages.placeholderText)}
        </span>
      </div>
    </CampaignsTileContainer>
  )
}

export default CampaignTile
